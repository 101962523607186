import React, { useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Container, Navbar, Nav, NavItem, NavLink, Button } from 'reactstrap';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const Layout = () => {
  const navigate = useNavigate()
  
  useEffect(() => {
    if(localStorage.token === undefined) {
      navigate('/auth/login')
    }
  }, [])

  return (
    <div className='default'>
      <Sidebar className="d-print-none" />
      <div className='default-content d-flex flex-column w-100 h-100'>
        <Header/>  
        <Container fluid className='bg-light p-4 h-100' style={{overflow: 'hidden', overflowY: 'auto'}}>
          <Outlet />
        </Container>
      </div>
      <div className='default-backdrop'></div>
    </div>
  )
};

export default Layout;