import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import moment from 'moment';

const Sidebar = () => {
  const { permissions, profile } = useAuth();
  
  return (
    <div className="sidebar bg-primary-dark h-100 text-white">
      <div className='sidebar-header text-center p-4 shadow'>
        <h1 className='fs-5 text-uppercase fw-bold m-0 p-0'>LP TRANSFER</h1>
      </div>
      <div className='py-4 ps-4'>
        <Nav vertical>
          {permissions.includes('view_trip') ?
          <NavItem>
            <NavLink to="/trips" className='sidebar-navlink text-white d-flex align-items-center p-3'>
              <i className="material-icons me-2">directions_car</i>
              <span className='sidebar-navlink-border-1'></span>
              <span className='sidebar-navlink-border-2'></span>
              <span className='sidebar-name'>Viagens</span> 
            </NavLink>
          </NavItem>
          : null}

          {permissions.includes('view_user') && profile?.groups[0].name === 'Administrators' ?
          <NavItem>
            <NavLink to="/customers" className='sidebar-navlink text-white d-flex align-items-center p-3'>
              <i className="material-icons me-2">domain</i>
              <span className='sidebar-navlink-border-1'></span>
              <span className='sidebar-navlink-border-2'></span>
              <span className='sidebar-name'>Empresas</span> 
            </NavLink>
          </NavItem>
          : null}

          {permissions.includes('view_user') && profile?.groups[0].name === 'Administrators' ?
          <NavItem>
            <NavLink to="/drivers" className='sidebar-navlink text-white d-flex align-items-center p-3'>
              <i className="material-icons me-2">badge</i>
              <span className='sidebar-navlink-border-1'></span>
              <span className='sidebar-navlink-border-2'></span>
              <span className='sidebar-name'>Motoristas</span> 
            </NavLink>
          </NavItem>
          : null}

          {permissions.includes('view_service') ?
          <NavItem>
            <NavLink to="/services" className='sidebar-navlink text-white d-flex align-items-center p-3'>
              <i className="material-icons me-2">emoji_transportation</i>
              <span className='sidebar-navlink-border-1'></span>
              <span className='sidebar-navlink-border-2'></span>
              <span className='sidebar-name'>Serviços</span> 
            </NavLink>
          </NavItem>
          : null}

          {permissions.includes('view_reservation') ?
          <NavItem>
            <NavLink to={`/reservations?date__gte=${moment().format('YYYY-MM-DD')}&date__lte=${moment().format('YYYY-MM-DD')}`} className='sidebar-navlink text-white d-flex align-items-center p-3'>
              <i className="material-icons me-2">directions_car</i>
              <span className='sidebar-navlink-border-1'></span>
              <span className='sidebar-navlink-border-2'></span>
              <span className='sidebar-name'>Reservas</span> 
            </NavLink>
          </NavItem>
          : null}

          {(permissions.includes('view_user') && profile?.groups[0].name === 'Administrators') || (profile?.groups[0].name === 'Customers' && (profile.is_company && profile.can_add_user)) ?
          <NavItem>
            <NavLink to="/users" className='sidebar-navlink text-white d-flex align-items-center p-3'>
              <i className="material-icons me-2">supervisor_account</i>
              <span className='sidebar-navlink-border-1'></span>
              <span className='sidebar-navlink-border-2'></span>
              <span className='sidebar-name'>Usuários</span> 
            </NavLink>
          </NavItem>
          : null}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;