import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import { useReservation } from '../context/ReservationContext';
import { useService } from '../context/ServiceContext';
import moment from 'moment';
import axios from 'axios';
import { addLinkWhatsApp } from '../utils';

import ptBR from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';

const Reservation = () => {
    const [fields, setFields] = useState({
        is_active: true,
        is_big_car: null
    });
    const [errors, setErrors] = useState({});
    const [occupiedDates, setOccupiedDates] = useState([]);
    const { users, getUsers, customers, drivers } = useUser();
    const { profile, permissions } = useAuth();
    const { allServices, getAllServices } = useService();
    const { createReservation, editReservation, changeStatus, getReservation, reservation, comments, getComments, createComment, loading, getReservationsH1, reservationsH1 } = useReservation();
    const { search } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);
    const page = params.get('page') ? parseInt(params.get('page')) : 1;
    const searchText = params.get('search') ? params.get('search') : '';

    const [loadingQtd, setLoadingQtd] = useState(false);
    const [comment, setComment] = useState('');

    const handleChange = (field, value) => {
        setFields({ ...fields, [field]: value });
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!fields['service']) {
            formIsValid = false;
            errors['service'] = 'Campo obrigatório.';
        }

        if (!fields['price']) {
            formIsValid = false;
            errors['price'] = 'Campo obrigatório.';
        }

        if (!fields['driver_fee'] && profile.groups[0].name === 'Administrators') {
            formIsValid = false;
            errors['driver_fee'] = 'Campo obrigatório.';
        }
    
        if (!fields['date']) {
          formIsValid = false;
          errors['date'] = 'Campo obrigatório.';
        }

        if (!fields['time']) {
            formIsValid = false;
            errors['time'] = 'Campo obrigatório.';
        }

        if (!fields['customer']) {
            formIsValid = false;
            errors['customer'] = 'Campo obrigatório.';
        }

        if (fields['is_big_car'] === null) {
            formIsValid = false;
            errors['is_big_car'] = 'Campo obrigatório.';
        }
    
        setErrors(errors);
        return formIsValid;
    }

    const hasPermission = () => {
        if(
               (id  && !permissions.includes('change_reservation'))
            || (!id && !permissions.includes('add_reservation'))
        ) {
            toast.warning('Seu usuário não tem permissões o suficiente para executar essa ação.');
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (handleValidation() && hasPermission()) {
            const now = moment();
            const selectedDateTime = moment(`${fields.date} ${fields.time}`, 'YYYY-MM-DD HH:mm');

            if(selectedDateTime.diff(now, 'hours') < 2 && profile.groups[0].name === 'Customers') {
                toast.info('A reserva precisa ser criada com pelo menos 2 horas de antecedencia.')
                return;
            }

            let res;
            let datetime = moment(fields.date).format('YYYY-MM-DD') +'T'+ fields.time + '-03:00' //2024-04-12T00:45:00-03:00

            if(id) {
                res = await editReservation(id, {...fields, date: moment(fields.date).format('YYYY-MM-DD'), datetime: datetime});
            } else {
                res = await createReservation({...fields, datetime: datetime});
            }
    
            if (res.status === 201) {
                toast.success('Reserva criado com sucesso.');
                navigate(`/reservations`);
            }
            else if (res.status === 200 && id) {
                toast.success('Reserva alterado com sucesso.');
                navigate(`/reservations`);
            } 
            else if(res.response.status === 400 || res.response.status === 403) {
                if(res.response.data?.detail) toast.error(res.response.data.detail);
                else {
                    let errors = {};
                    Object.keys(res.response.data).forEach((key) => {
                        errors[key] = res.response.data[key][0];
                    })
                    setErrors(errors)
                }
            }
            else {
                toast.error('Ops! Ocorreu um erro inesperado. Tente novamente. Se o erro persistir, entre em contato com o suporte.');
            }
        }
    }

    const getData = async() => {
        if(id) {
            const res = await getReservation(id);
            setFields({...res.data, date: moment(res.data.date, 'YYYY-MM-DD').toDate()});
        }
    }

    const handleChangeStatus = async (status) => {
        const res = await changeStatus(id, status);
        setFields(res.data)
    }
    
    const setAmountOfBags = async (qtd) => {
        setLoadingQtd(true);
        let quantity = parseInt(qtd) >= 0 ? parseInt(qtd) : 0;
        handleChange('amount_of_bags', quantity)
        
        const res = await axios.post(`/api/reservations/${id}/change-amount-of-bags/`, {amount_of_bags: quantity});

        setLoadingQtd(false);
        
        if (res.status !== 200) {
            toast.error('Ops! Ocorreu um erro inesperado. Tente novamente. Se o erro persistir, entre em contato com o suporte.');
        }
    }

    const getDateStatus = (status) => {
        status = fields?.status_details?.find((_item) => _item.status === status)
        let datetime = status?.datetime
        if(datetime) {
            return moment(datetime).format('DD/MM/YYYY - HH:mm') + ` - ${status.first_name} ${status.last_name} (${status.username})`
        }
        return null;
    }

    useEffect(() => {
        // console.log('profile', profile)
        handleChange('customer', profile.id)
    }, ['profile'])

    useEffect(() => {
        getData();

        getComments(id);
        if(id) {
            setTimeout(() => {
                getComments(id);
            }, 1000)
        }
        
        let customers_ids = '';
        if(profile.groups[0].name === 'Customers') {
            customers_ids = profile.can_view_customer.length ? profile.can_view_customer.join(',') : ''
        }
        getUsers(1, 1, '', true, customers_ids);
        getUsers(2, 1, '', true);
        getAllServices(1, '', true);
    }, [])

    return (
        <>
            <h2 className='fs-6 fw-semibold my-4'>{!id ? 'Criar reserva' : `Editar #${reservation?.id}`}</h2>

            <Card>
                <CardBody>
                    <Form onSubmit={handleSubmit} noValidate>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label check htmlFor='service'>Serviço</Label>
                                    <Input
                                        type='select'
                                        id='service'
                                        name='service'
                                        value={fields?.service}
                                        invalid={errors?.service}
                                        disabled={profile.groups[0].name === 'Drivers' || (id && profile.groups[0].name === 'Customers' && fields?.driver) || (profile.groups[0].name === 'Administrators' && !profile.can_edit_fields.split(',').includes('service'))}
                                        onChange={async (e) => {
                                            let service = allServices.find((item) => item.id === parseInt(e.target.value))

                                            if(service.is_h1) {                                                
                                                const res = await getReservationsH1()
                                                let dates = res.data.map((item) => item.date)
                                                setOccupiedDates(dates)
                                            } else {
                                                setOccupiedDates([])
                                            }
                                            
                                            setFields({ 
                                                ...fields, 
                                                service: e.target.value,
                                                price: parseFloat(service.price),
                                                driver_fee: service.driver_fee ? parseFloat(service.driver_fee) : 0
                                            });
                                        }}
                                    >
                                        <option value='' selected disabled></option>
                                        {allServices?.map((service, key) => {
                                            return <option value={service.id} key={key}>{service.name}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>{errors?.service}</FormFeedback>
                                </FormGroup>
                            </Col>

                            {(profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers') && profile.can_view_price ? 
                            <Col>
                                <FormGroup>
                                    <Label check htmlFor='price'>Preço</Label>
                                    <Input
                                    type='price'
                                    id='price'
                                    name='price'
                                    disabled={profile.groups[0].name !== 'Administrators' || (profile.groups[0].name === 'Administrators' && !profile.can_edit_fields.split(',').includes('price'))}
                                    value={fields?.price}
                                    invalid={errors?.price}
                                    onChange={(e) => {
                                        handleChange('price', e.target.value)
                                    }}
                                    />
                                    <FormFeedback>{errors?.price}</FormFeedback>
                                </FormGroup>
                            </Col>
                            : null}

                            {profile.groups[0].name === 'Administrators' ? 
                                <Col>
                                    <FormGroup>
                                        <Label check htmlFor='driver_fee'>Comissão do motorista</Label>
                                        <Input
                                        type='driver_fee'
                                        id='driver_fee'
                                        name='driver_fee'
                                        value={fields?.driver_fee}
                                        invalid={errors?.driver_fee}
                                        disabled={(profile.groups[0].name === 'Administrators' && !profile.can_edit_fields.split(',').includes('driver_fee'))}
                                        onChange={(e) => {
                                            handleChange('driver_fee', e.target.value)
                                        }}
                                        />
                                        <FormFeedback>{errors?.driver_fee}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            : null}
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label check htmlFor='date'>Data</Label>
                                    <DatePicker
                                        className='form-control'
                                        selected={fields?.date ? moment(fields?.date, 'YYYY-MM-DD').toDate() : null}
                                        onChange={(date) => {
                                            console.log('date', moment(date).format('YYYY-MM-DD'))
                                            setFields({ ...fields, date: moment(date).format('YYYY-MM-DD') });
                                        }}
                                        excludeDates={!profile.is_superuser ? occupiedDates.map(date => moment(date, 'YYYY-MM-DD').toDate()) : null}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="DD/MM/YYYY"
                                        // minDate={new Date()}
                                        locale={ptBR}
                                    />
                                    <FormFeedback>{errors?.date}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label check htmlFor='time'>Hora</Label>
                                    <Input
                                    type='time'
                                    id='time'
                                    name='time'
                                    disabled={profile.groups[0].name === 'Drivers' || (id && profile.groups[0].name === 'Customers' && fields?.driver) || (profile.groups[0].name === 'Administrators' && !profile.can_edit_fields.split(',').includes('time'))}
                                    value={fields?.time}
                                    invalid={errors?.time}
                                    onChange={(e) => handleChange('time', e.target.value)}
                                    />
                                    <FormFeedback>{errors?.time}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Drivers' || (profile.groups[0].name === 'Customers' && profile.can_view_customer.length) ?
                            <Col>
                                <FormGroup>
                                    <Label check htmlFor='customer'>Empresa</Label>
                                    <Input
                                        type='select'
                                        id='customer'
                                        name='customer'
                                        disabled={profile.groups[0].name === 'Drivers' || (id && profile.groups[0].name === 'Customers' && fields?.driver) || (profile.groups[0].name === 'Administrators' && !profile.can_edit_fields.split(',').includes('customer'))}
                                        value={fields?.customer}
                                        invalid={errors?.customer}
                                        onChange={(e) => handleChange('customer', e.target.value)}
                                    >
                                        {
                                            profile.groups[0].name === 'Customers' ? 
                                                <option value={profile.id}>{profile.first_name +' '+ profile.last_name}</option> 
                                            : 
                                                <option value=''></option>
                                            }
                                        {customers?.sort((a, b) => {
                                            const nameA = a.first_name.toLowerCase();
                                            const nameB = b.first_name.toLowerCase();
                                            return nameA.localeCompare(nameB);
                                        })?.map((customer, key) => {
                                            return <option value={customer.id} key={key}>{customer.first_name} {customer.last_name}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>{errors?.customer}</FormFeedback>
                                </FormGroup>
                            </Col>
                            : null}

                            <Col>
                                <FormGroup>
                                    <Label check htmlFor='driver'>Motorista</Label>
                                    <Input
                                    type='select'
                                    id='driver'
                                    name='driver'
                                    value={fields?.driver}
                                    invalid={errors?.driver}
                                    disabled={profile.groups[0].name !== 'Administrators' || !profile.can_edit_fields.split(',').includes('driver') || (fields?.status)}
                                    onChange={(e) => handleChange('driver', e.target.value)}
                                    >
                                        <option value=''></option>
                                        {drivers?.sort((a, b) => {
                                            const nameA = a.first_name.toLowerCase();
                                            const nameB = b.first_name.toLowerCase();
                                            return nameA.localeCompare(nameB);
                                        })?.map((driver, key) => {
                                            return <option value={driver.id} key={key}>{driver.first_name} {driver.last_name}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>{errors?.driver}</FormFeedback>
                                </FormGroup>
                            </Col>

                            {profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers' ?
                            <Col>
                                <FormGroup>
                                    <Label check htmlFor='is_big_car'>Carro grande</Label>
                                    <Input
                                    type='select'
                                    id='is_big_car'
                                    name='is_big_car'
                                    value={fields?.is_big_car}
                                    invalid={errors?.is_big_car}
                                    disabled={(id && profile.groups[0].name === 'Customers' && fields?.driver) || (profile.groups[0].name === 'Administrators' && !profile.can_edit_fields.split(',').includes('is_big_car'))}
                                    onChange={(e) => handleChange('is_big_car', e.target.value)}
                                    >
                                        <option value='' selected disabled></option>
                                        <option value={false}>NÃO</option>
                                        <option value={true}>SIM</option>
                                    </Input>
                                    <FormFeedback>{errors?.is_big_car}</FormFeedback>
                                </FormGroup>
                            </Col>
                            : null}

                            {profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Drivers' ?
                            <Col>
                                <FormGroup>
                                    <Label check htmlFor='amount_of_bags'>Quantidade de malas {loadingQtd ? <Spinner size='sm' /> : null} </Label>
                                    <Input
                                        type='number'
                                        min={0}
                                        id='amount_of_bags'
                                        name='amount_of_bags'
                                        disabled={profile.groups[0].name !== 'Drivers'}
                                        value={fields?.amount_of_bags}
                                        invalid={errors?.amount_of_bags}
                                        onChange={(e) => setAmountOfBags(e.target.value)}
                                    />
                                    <FormFeedback>{errors?.amount_of_bags}</FormFeedback>
                                </FormGroup>
                            </Col>
                            : null}
                        </Row>
                        <Row>
                            <Col md={12}>
                                {!((id && profile.groups[0].name === 'Customers' && fields?.driver) || profile.groups[0].name === 'Drivers') ? 
                                <FormGroup>
                                    <Label check htmlFor='comments'>Observações</Label>
                                    <Input
                                        type='textarea'
                                        id='comments'
                                        name='comments'
                                        disabled={(id && profile.groups[0].name === 'Customers' && fields?.driver) || profile.groups[0].name === 'Drivers'}
                                        value={fields?.comments}
                                        invalid={errors?.comments}
                                        onChange={(e) => handleChange('comments', e.target.value)}
                                    />
                                    <FormFeedback>{errors?.comments}</FormFeedback>
                                </FormGroup>
                                : 
                                    <div className='form-control disabled'>
                                        {fields?.comments ? <pre className='pre' dangerouslySetInnerHTML={{ __html: addLinkWhatsApp(fields?.comments) }} /> : null}
                                    </div>
                                }
                            </Col>
                        </Row>
                        
                        <Row>
                            {(profile.groups[0].name === 'Drivers' && profile.can_view_price) || profile.groups[0].name === 'Administrators' ?
                            <Col md={6}>
                                <FormGroup>
                                    <Label check htmlFor='driver_payment'>Pagamento do motorista</Label>
                                    <Input
                                    type='select'
                                    id='driver_payment'
                                    name='driver_payment'
                                    value={fields?.driver_payment}
                                    invalid={errors?.driver_payment}
                                    disabled={profile.groups[0].name !== 'Administrators' || (profile.groups[0].name === 'Administrators' && !profile.can_edit_fields.split(',').includes('driver_payment'))}
                                    onChange={(e) => handleChange('driver_payment', e.target.value)}
                                    >
                                        <option value='false'>Pendente</option>
                                        <option value='true'>Pago</option>
                                    </Input>
                                    <FormFeedback>{errors?.driver_payment}</FormFeedback>
                                </FormGroup>
                            </Col>
                            : null}

                            {profile.groups[0].name === 'Administrators' ?
                            <Col md={6}>
                                <FormGroup>
                                    <Label check htmlFor='customer_payment'>Pagamento da empresa</Label>
                                    <Input
                                    type='select'
                                    id='customer_payment'
                                    name='customer_payment'
                                    value={fields?.customer_payment}
                                    invalid={errors?.customer_payment}
                                    disabled={profile.groups[0].name !== 'Administrators' || (profile.groups[0].name === 'Administrators' && !profile.can_edit_fields.split(',').includes('customer_payment'))}
                                    onChange={(e) => handleChange('customer_payment', e.target.value)}
                                    >
                                        <option value='false'>Pendente</option>
                                        <option value='true'>Pago</option>
                                    </Input>
                                    <FormFeedback>{errors?.customer_payment}</FormFeedback>
                                </FormGroup>
                            </Col>
                            : null}
                        </Row>
                        

                        {profile.is_superuser || (profile.groups[0].name === 'Customers' && !profile.company) ?
                            <p>
                                <b>Criado por:</b> {fields?.created_by} <br />
                                <b>Criado em:</b>  {moment(fields?.created_at).format('DD/MM/YYYY - HH:mm')} <br /><br />
                                <b>Atualizado por:</b> {fields?.updated_by} <br />
                                <b>Atualizado em:</b>  {moment(fields?.updated_at).format('DD/MM/YYYY - HH:mm')}
                            </p>
                        : null}

                        <div className='mt-4'>
                        {id === undefined && permissions.includes('add_reservation') ? 
                            <Button color='primary'>Criar</Button> 
                        : null}

                        {(id && permissions.includes('change_reservation') && profile.groups[0].name === 'Administrators') || (id && permissions.includes('change_reservation') && profile.groups[0].name === 'Customers') && !fields?.driver ? 
                            <Button color='primary'>Guardar</Button> 
                        : null}
                        </div>
                    </Form>
                </CardBody>
            </Card>

            {id ?
                <Card className='mt-4'>
                    <CardBody>
                        <div>
                            <div>
                                <div className='d-flex align-items-center mb-1'>
                                    {fields.status ? <i className="material-icons fs-5 me-1">task_alt</i> : <i className="material-icons fs-5 me-1">radio_button_unchecked</i>} A caminho {getDateStatus('1')}
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    {fields.status && parseInt(fields.status) >= 2 ? <i className="material-icons fs-5 me-1">task_alt</i> : <i className="material-icons fs-5 me-1">radio_button_unchecked</i>} Esperando o cliente {getDateStatus('2')}
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    {fields.status && parseInt(fields.status) >= 3 ? <i className="material-icons fs-5 me-1">task_alt</i> : <i className="material-icons fs-5 me-1">radio_button_unchecked</i>} Viagem iniciada {getDateStatus('3')}
                                </div>
                                <div className='d-flex align-items-center mb-1'>
                                    {fields.status && parseInt(fields.status) >= 4 ? <i className="material-icons fs-5 me-1">task_alt</i> : <i className="material-icons fs-5 me-1">radio_button_unchecked</i>} Viagem finalizada {getDateStatus('4')}
                                </div>
                            </div>
                        
                            {(profile.groups[0].name === 'Drivers' || profile.groups[0].name === 'Administrators') ? 
                            <div>
                                {fields.status === null ? 
                                    <Button type='button' color='primary' className='mt-4' onClick={() => handleChangeStatus('1')}>Ir para o local</Button>
                                : null}
                                {fields.status === '1' ? 
                                    <Button type='button' color='primary' className='mt-4' onClick={() => handleChangeStatus('2')}>Esperar o cliente</Button>
                                : null}
                                {fields.status === '2' ? 
                                    <Button type='button' color='primary' className='mt-4' onClick={() => handleChangeStatus('3')}>Iniciar viagem</Button>
                                : null}
                                {fields.status === '3' ? 
                                    <Button type='button' color='primary' className='mt-4' onClick={() => handleChangeStatus('4')}>Finalizar viagem</Button>
                                : null}
                            </div>
                            : null}
                        </div>
                    </CardBody>
                </Card>
            : null}

            {id ? 
                <Card className='mt-4'>
                    <CardBody>
                        <h6>Comentários</h6>
                        
                        {comments?.map((item, key) => {
                            const commentWithLineBreaks = item.comment.replace(/\n/g, "<br />");

                            return (
                                <div key={key} className='border rounded bg-secondary bg-opacity-10 p-2 mt-2'>
                                    <p><b>{item.created_by__name}</b> - {moment(item.created_at).format('DD-MM-YYYY HH:mm')}</p>
                                    <div dangerouslySetInnerHTML={{ __html: commentWithLineBreaks }} />
                                </div>
                            );
                        })}

                        {comments === null ? 
                                <div className='d-flex align-items-center justify-content-center my-4'>
                                <Spinner className='me-2' size='sm' /> Carregando...
                            </div>
                        : null}

                        <Input 
                            type="textarea"
                            className='mt-4'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <div className='text-end'>
                            <Button color='primary' className='mt-2' onClick={async () => {
                                let res = await createComment(id, {reservation: id, comment: comment})
                                getComments(id)
                                setComment('')

                                if (res.status === 201) {
                                    toast.success('Comentário enviado com sucesso.');
                                }
                                else {
                                    toast.error('Ops! Ocorreu um erro inesperado. Tente novamente. Se o erro persistir, entre em contato com o suporte.');
                                }
                                
                            }}>Enviar</Button>
                        </div>
                    </CardBody>
                </Card>
            : null}
        </>
    )
};
  
export default Reservation;