import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';

import AuthProvider from './context/AuthContext';
import UserProvider from './context/UserContext';
import ServiceProvider from './context/ServiceContext';
import ReservationProvider from './context/ReservationContext';
import NotificationProvider from './context/NotificationContext';

import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './scss/styles.scss';

registerLocale('pt-BR', ptBR);

axios.defaults.baseURL = process.env.REACT_APP_URL_API;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

if(localStorage.token) {
  axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.token;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
      <UserProvider>
        <ServiceProvider>
          <ReservationProvider>
            <NotificationProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </NotificationProvider>
          </ReservationProvider>
        </ServiceProvider>
      </UserProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
