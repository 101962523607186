import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

const ServiceContext = createContext();

export const useService = () => useContext(ServiceContext);

const ServiceProvider = ({ children }) => {
  const [services, setServices] = useState(null);
  const [allServices, setAllServices] = useState([]);
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(null);

  const getServices = async (page=1, search='', isArchived=false) => {
    setLoading('get_services');
    try {
      const res = await axios.get(`/api/services/?page=${page}&search=${search}&is_archived=${isArchived}`);
      setServices(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getAllServices = async () => {
    setLoading('get_all_services');
    try {
      const res = await axios.get(`/api/services/?no_pagination=true&is_archived=false`);
      setAllServices(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getService = async (id) => {
    setLoading('get_service');
    try {
      const res = await axios.get(`/api/services/${id}/`);
      setService(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setService(null);
      setLoading(null);
      return error;
    }
  };

  const deleteService = async (id) => {
    setLoading('delete_service');
    try {
      const res = await axios.delete(`/api/services/${id}/`);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const createService = async (data) => {
    setLoading('create_service');
    try {
      const res = await axios.post(`/api/services/`, data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  const editService = async (id, data) => {
    setLoading('create_service');
    try {
      const res = await axios.patch(`/api/services/${id}/`, data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  const archiveService = async (id) => {
    setLoading('archive_service');
    try {
      const res = await axios.get(`/api/services/${id}/archive/`);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  return (
    <ServiceContext.Provider
      value={{
        services,
        allServices,
        service,
        loading,
        getServices,
        getAllServices,
        getService,
        deleteService,
        createService,
        editService,
        archiveService
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export default ServiceProvider;