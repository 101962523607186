import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import { extractCodenames } from '../utils';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const login = async (username, password) => {
    setLoading(true);
    try {
      const res = await axios.post('/api/auth/login/', {username: username, password: password});
      localStorage.token = res.data.token;
      axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.token;
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const logout = async () => {
    try {
      axios.post('/api/auth/logout/');
      setUser(null);
      delete axios.defaults.headers.common['Authorization'];
      localStorage.clear();
      return;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const isAuthenticated = () => {
    return !!user;
  };

  const getProfile = async () => {
    setLoading(true);

    let profile;
    let permissions;

    if(localStorage.profile) {
      profile = JSON.parse(localStorage.profile);
      permissions = extractCodenames(profile?.groups);
      setProfile(profile);
      setPermissions(permissions)
    }

    try {
      const res = await axios.get('/api/auth/profile/');
      profile = res.data;
      permissions = extractCodenames(profile?.groups);
      localStorage.profile = JSON.stringify(profile);
      setProfile(profile);
      setPermissions(permissions)
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const resetPassword = async (email) => {
    setLoading(true);
    try {
      const res = await axios.post('/api/auth/reset-password/', {email: email});
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        permissions,
        loading,
        login,
        logout,
        isAuthenticated,
        getProfile,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;