import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

const ReservationContext = createContext();

export const useReservation = () => useContext(ReservationContext);

const ReservationProvider = ({ children }) => {
  const [reservations, setReservations] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [reservationsH1, setReservationsH1] = useState(null);
  const [comments, setComments] = useState(null);
  const [customersReport, setCustomersReport] = useState(null);
  const [driversReport, setDriversReport] = useState(null);
  const [servicesReport, setServicesReport] = useState(null);
  const [loading, setLoading] = useState(null);
  const [totalDriverPayment, setTotalDriverPayment] = useState(null);
  const [totalIncompleteRides, setTotalIncompleteRides] = useState(null);
  const [totalAmountToReceive, setTotalAmountToReceive] = useState(null);
  const [totalAmountToPayment, setTotalAmountToPayment] = useState(null);

  const getReservations = async (page=1, search='', date__gte='', date__lte='', time='', service='', customer__in='', driver__in='', driver_payment='', customer_payment='') => {
    setLoading('get_reservations');
    try {
      const res = await axios.get(`/api/reservations/?page=${page}&search=${search}&date__gte=${date__gte}&date__lte=${date__lte}&time=${time}&service=${service}&customer__in=${customer__in}&driver__in=${driver__in}&driver_payment=${driver_payment}&customer_payment=${customer_payment}`);
      setReservations(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getReservation = async (id) => {
    setLoading('get_reservation');
    try {
      const res = await axios.get(`/api/reservations/${id}/`);
      setReservation(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setReservation(null);
      setLoading(null);
      return error;
    }
  };

  const deleteReservation = async (id) => {
    setLoading('delete_reservation');
    try {
      const res = await axios.delete(`/api/reservations/${id}/`);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const createReservation = async (data) => {
    setLoading('create_reservation');
    try {
      const res = await axios.post(`/api/reservations/`, data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  const editReservation = async (id, data) => {
    setLoading('create_reservation');
    try {
      const res = await axios.patch(`/api/reservations/${id}/`, data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  const changeStatus = async (id, status) => {
    setLoading('change_status');
    try {
      const res = await axios.post(`/api/reservations/${id}/change-status/`, {status: status});
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getTotalDriverPayment = async (search='', date__gte='', date__lte='', time='', service='', customer__in='', driver__in='', driver_payment='', customer_payment='') => {
    try {
      const res = await axios.get(`/api/reservations/total-driver-payment/?search=${search}&date__gte=${date__gte}&date__lte=${date__lte}&time=${time}&service=${service}&customer__in=${customer__in}&driver__in=${driver__in}&driver_payment=${driver_payment}&customer_payment=${customer_payment}`);
      setTotalDriverPayment(res.data);
      return res;
    } catch (error) {
      setTotalDriverPayment(null);
      return error;
    }
  };

  const getTotalIncompleteRides = async (search='', date__gte='', date__lte='', time='', service='', customer__in='', driver__in='', driver_payment='', customer_payment='') => {
    try {
      const res = await axios.get(`/api/reservations/total-incomplete-rides/?search=${search}&date__gte=${date__gte}&date__lte=${date__lte}&time=${time}&service=${service}&customer__in=${customer__in}&driver__in=${driver__in}&driver_payment=${driver_payment}&customer_payment=${customer_payment}`);
      setTotalIncompleteRides(res.data);
      return res;
    } catch (error) {
      setTotalIncompleteRides(null);
      return error;
    }
  };

  const getTotalAmountToReceive = async (search='', date__gte='', date__lte='', time='', service='', customer__in='', driver__in='', driver_payment='', customer_payment='') => {
    try {
      const res = await axios.get(`/api/reservations/total-amount-to-receive/?search=${search}&date__gte=${date__gte}&date__lte=${date__lte}&time=${time}&service=${service}&customer__in=${customer__in}&driver__in=${driver__in}&driver_payment=${driver_payment}&customer_payment=${customer_payment}`);
      setTotalAmountToReceive(res.data)
      return res;
    } catch (error) {
      return error;
    }
  };

  const getTotalAmountToPayment = async (search='', date__gte='', date__lte='', time='', service='', customer__in='', driver__in='', driver_payment='', customer_payment='') => {
    try {
      const res = await axios.get(`/api/reservations/total-amount-to-payment/?search=${search}&date__gte=${date__gte}&date__lte=${date__lte}&time=${time}&service=${service}&customer__in=${customer__in}&driver__in=${driver__in}&driver_payment=${driver_payment}&customer_payment=${customer_payment}`);
      setTotalAmountToPayment(res.data)
      return res;
    } catch (error) {
      return error;
    }
  };

  const bulkUpdate = async (data) => {
    setLoading('bulk_update_reservation');
    try {
      const res = await axios.patch(`/api/reservations/bulk-update/`, data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  const getComments = async (id) => {
    setLoading('get_comments');
    try {
      const res = await axios.get(`/api/reservations/${id}/comments/`);
      setComments(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setComments(null);
      setLoading(null);
      return error;
    }
  };

  const createComment = async (id, data) => {
    setLoading('create_comments');
    try {
      const res = await axios.post(`/api/reservations/${id}/comments/`, data);
      setLoading(null);
      return res
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getCustomersReport = async (search='', date__gte='', date__lte='', time='', service='', customer__in='', driver__in='', driver_payment='', customer_payment='') => {
    setLoading('get_customers_report');
    try {
      const res = await axios.get(`/api/reservations/customers-report/?search=${search}&date__gte=${date__gte}&date__lte=${date__lte}&time=${time}&service=${service}&customer__in=${customer__in}&driver__in=${driver__in}&driver_payment=${driver_payment}&customer_payment=${customer_payment}`);
      setCustomersReport(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setCustomersReport(null);
      setLoading(null);
      return error;
    }
  };

  const getDriversReport = async (search='', date__gte='', date__lte='', time='', service='', customer__in='', driver__in='', driver_payment='', customer_payment='') => {
    setLoading('get_drivers_report');
    try {
      const res = await axios.get(`/api/reservations/drivers-report/?search=${search}&date__gte=${date__gte}&date__lte=${date__lte}&time=${time}&service=${service}&customer__in=${customer__in}&driver__in=${driver__in}&driver_payment=${driver_payment}&customer_payment=${customer_payment}`);
      setDriversReport(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setDriversReport(null);
      setLoading(null);
      return error;
    }
  };

  const getServicesReport = async (search='', date__gte='', date__lte='', time='', service='', customer__in='', driver__in='', driver_payment='', customer_payment='') => {
    setLoading('get_driver_services_report');
    try {
      const res = await axios.get(`/api/reservations/services-report/?search=${search}&date__gte=${date__gte}&date__lte=${date__lte}&time=${time}&service=${service}&customer__in=${customer__in}&driver__in=${driver__in}&driver_payment=${driver_payment}&customer_payment=${customer_payment}`);
      setServicesReport(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setServicesReport(null);
      setLoading(null);
      return error;
    }
  };

  const getReservationsH1 = async () => {
    setLoading('get_reservations_h1');
    try {
      const res = await axios.get(`/api/reservations/?service__is_h1=true`);
      setReservationsH1(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  return (
    <ReservationContext.Provider
      value={{
        reservations,
        reservation,
        loading,
        totalDriverPayment,
        totalIncompleteRides,
        totalAmountToReceive,
        totalAmountToPayment,
        comments,
        customersReport,
        driversReport,
        servicesReport,
        reservationsH1,
        getReservations,
        getReservation,
        deleteReservation,
        createReservation,
        editReservation,
        changeStatus,
        getTotalDriverPayment,
        getTotalIncompleteRides,
        getTotalAmountToReceive,
        getTotalAmountToPayment,
        bulkUpdate,
        getComments,
        createComment,
        getCustomersReport,
        getDriversReport,
        getServicesReport,
        getReservationsH1
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export default ReservationProvider;