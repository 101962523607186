import React, { useEffect } from 'react';
import { Table, Spinner, Badge, Button, Row, Col, Card, CardBody } from 'reactstrap';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { useService } from '../context/ServiceContext';
import CustomPagination from '../components/CustomPagination';
import Search from '../components/Search';
import avatar from '../assets/images/avatar.png'

const Services = () => {
    const { profile, permissions } = useAuth();
    const { services, getServices, archiveService, deleteService, loading } = useService();
    const { search } = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);
    const page = params.get('page') ? parseInt(params.get('page')) : 1;
    const searchText = params.get('search') ? params.get('search') : '';

    useEffect(() => {
        getServices(page, searchText, true);
    }, [page, searchText])

    const handleArchived = (item) =>{
        confirmAlert({
            title: <p className='fs-6 fw-semibold mb-0'>Desarquivar serviço</p>,
            message: <p>Tem certeza que deseja desarquivar <b>{item.name}</b></p>,
            buttons: [
              {
                label: 'Sim',
                onClick: async () => {
                    let res = await archiveService(item.id);
                    getServices(page, searchText, true);

                    if(res.status == 200) {
                        toast.success(`${item.name} foi desarquivado com sucesso!`);
                    }
                    else {
                        toast.error(`Não foi possível desarquivar ${item.name}`);
                    }
                }
              },
              {
                label: 'Não'    
              }
            ]
        })
    }

    const handleDelete = (item) => {
        confirmAlert({
            title: <p className='fs-6 fw-semibold mb-0'>Eliminar serviço</p>,
            message: <p>Tem certeza que deseja eliminar <b>{item.name}</b></p>,
            buttons: [
              {
                label: 'Sim',
                onClick: async () => {
                    let res = await deleteService(item.id);
                    getServices(page, searchText, true);

                    if(res.status == 204) {
                        toast.success(`${item.name} foi eliminado com sucesso!`);
                    }
                    else {
                        toast.error(`Não foi possível eliminar ${item.name}`);
                    }
                }
              },
              {
                label: 'Não'    
              }
            ]
        });
    }

    return (
        <>
            <h2 className='fs-6 fw-semibold my-4'>Serviços</h2>

            <Row className='mb-4'>
                <Col>
                    {permissions.includes('add_service') ? <Button color='primary' onClick={() => navigate('/services/create')}>Adicionar serviço</Button> : null}
                </Col>
                {permissions.includes('change_service') ? 
                    <Col style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Link to={`/services/`}>Ver Desarquivados</Link>
                    </Col>
                : null}
                <Col className='text-end'>
                    <div className='d-inline-block'>
                        <Search />
                    </div>
                </Col>
            </Row>

            {
                loading === 'get_services' ? 
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner className='me-1' /> Carregando...
                </div>
                : 
                <Table responsive className='table-clean'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>NOME</th>
                                <th>PREÇO</th>
                                <th>COMISSÃO</th>
                                <th>A1</th>
                                <th className='text-center'>STATUS</th>
                                <th className='text-center'>AÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {services?.results.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td><Link to={`/services/${item.id}/`}>{item.id}</Link></td>
                                        <td>{item.name}</td>
                                        <td>{item.price}</td>
                                        <td>{item.driver_fee}</td>
                                        <td>{item.is_h1 ? 'SIM' : 'NÃO'}</td>
                                        <td className={'text-center ' + (item.is_active ? 'text-success' : 'text-danger')}>
                                            <Badge color={item.is_active ? 'success' : 'danger'}>{item.is_active ? 'Ativo' : 'Desativado'}</Badge>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                {permissions.includes('change_service') ? 
                                                <Button size='sm' color='secondary' outline className='d-flex align-items-center'  onClick={() => navigate(`/services/${item.id}/`)}>
                                                    <i className="material-icons fs-5">edit</i>
                                                    Editar
                                                </Button>
                                                : null}

                                                {permissions.includes('change_service') ? 
                                                <Button size='sm' color='secondary' outline className='d-flex align-items-center ms-2'  onClick={() => handleArchived(item)}>
                                                    <i className="material-icons fs-5">unarchive</i>
                                                    Desarquivar
                                                </Button>
                                                : null}

                                                {permissions.includes('delete_service') ?
                                                <Button size='sm' color='danger' className='d-flex align-items-center ms-2' onClick={() => handleDelete(item)}>
                                                    <i className="material-icons fs-5">delete</i>
                                                    Deletar
                                                </Button> 
                                                : null}
                                            </div>
                                            
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            }

            {services && (services.count / 100) > 1 ?
            <div className='d-flex justify-content-center'>
                <CustomPagination currentPage={page} totalPages={services.count / 100} maxPagesToShow={5} />
            </div>
            : null}

            {
                searchText.length && services?.count === 0 ?
                <Card>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        <p className='m-0'>Nenhum resultado foi encontrado para <b>{searchText}</b></p>
                    </CardBody>
                </Card>
                : 
                null
            }
        </>
    )
};
  
export default Services;