import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, FormFeedback, Label, Input, Button, Spinner, Card, CardBody } from 'reactstrap';
import { useNavigate, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { Presentation } from '../components/Presentation';
import moment from 'moment';

const Login = () => {
  const { login, getProfile, loading } = useAuth();
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!fields['username']) {
      formIsValid = false;
      errors['username'] = 'Campo obrigatório.';
    }

    if (typeof fields['username'] !== 'undefined') {
        let lastAtPos = fields['username'].lastIndexOf('@');
        let lastDotPos = fields['username'].lastIndexOf('.');

        if (
            !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields['username'].indexOf('@@') == -1 &&
            lastDotPos > 2 &&
            fields['username'].length - lastDotPos > 2
            )
        ) {
        formIsValid = false;
        errors['username'] = 'O e-mail é inválido.';
      }
    }

    if (!fields['password']) {
        formIsValid = false;
        errors['password'] = 'Campo obrigatório.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const res = await login(fields.username, fields.password);

      if (res.status === 200) {
        const res = await getProfile()
        toast.dismiss();
        navigate(res.data.groups[0].name === 'Customers' ? '/reservations' : `/reservations?date__gte=${moment().format('YYYY-MM-DD')}&date__lte=${moment().format('YYYY-MM-DD')}`);
      } 
      else if(res.response.status === 401) {
        if(res.response.data?.detail) toast.error(res.response.data.detail);
        else {
            let errors = {};
            Object.keys(res.response.data).forEach((key) => {
                errors[key] = res.response.data[key][0];
            })
            toast.error(errors);
        }
      }
      else {
        toast.error('Ops! Ocorreu um erro inesperado. Tente novamente. Se o erro persistir, entre em contato com o suporte.');
      }
    }
  }

  return (
    <>
    <div className='login-circle-1'></div>
    <div className='login-circle-2'></div>
      <Container className='h-100'>
        <Row className='h-100'>
          <Col className='login-text' md={{size: 12}} xl={{size: 6}}>
            <Presentation />
          </Col>
          <Col md={{size: 12}} xl={{size: 6}} className='d-flex justify-content-center align-items-center'>
            <Card className='border-0' style={{width: 500}}>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h1 className='fs-3 fw-bold mb-4'>Entrar</h1>
                  <FormGroup>
                    <Input
                      type='text'
                      name='username'
                      placeholder='E-mail'
                      style={{height: 45}}
                      value={fields?.username}
                      invalid={errors?.username}
                      onChange={(e) => handleChange('username', e.target.value)}
                    />
                    <FormFeedback>{errors?.username}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type='password'
                      name='password'
                      placeholder='Senha'
                      style={{height: 45}}
                      value={fields?.password}
                      invalid={errors?.password}
                      onChange={(e) => handleChange('password', e.target.value)}
                    />
                    <FormFeedback>{errors?.password}</FormFeedback>
                  </FormGroup>
                  <Row className='mb-4'>
                    <Col>
                      <FormGroup check>
                        <Input
                          id="remember-me"
                          type="checkbox"
                        />
                        {' '}
                        <Label check>
                          Lembrar de mim
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col className='text-end'>
                      <NavLink to="/auth/recover-password">Esqueceu sua senha?</NavLink>
                    </Col>
                  </Row>
                  <Button color='primary' size='sm' type='submit' disabled={loading} style={{width: 150, height: 45}}>
                    {loading ? 'Aguarde' : 'Entrar'}
                    {loading ? <Spinner size='sm' className='ms-1' /> : null}
                  </Button>
                  {/* <Button color='secondary' size='sm' type='submit' disabled={true} style={{width: 150, height: 45}} outline className='ms-3'>
                    {loading ? 'Aguarde' : 'Registra-se'}
                    {loading ? <Spinner size='sm' className='ms-1' /> : null}
                  </Button> */}
                </Form>
                {/* <p className='mt-5'>Ao se inscrever, você concorda com nossos <a href="#">Termos e Condições</a> e <a href="#">Política de Privacidade</a></p> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
