import React, { useEffect, useState } from 'react';
import { Table, Spinner, Badge, ButtonGroup, Button, Row, Col, Card, CardBody, Tooltip, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import moment from 'moment';
import queryString from 'query-string';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import { useService } from '../context/ServiceContext';
import { useReservation } from '../context/ReservationContext';
import DatePagination from '../components/DatePagination';
import Search from '../components/Search';
import Filter from '../components/Filter';
import { addLinkWhatsApp } from '../utils';

const Reservations = () => {
    const { profile, permissions } = useAuth();
    const { customers, drivers, getUsers } = useUser();
    const { allServices, getAllServices } = useService();
    const { 
        reservations,
        getReservations,
        deleteReservation,
        loading,
        totalDriverPayment,
        totalAmountToReceive,
        totalAmountToPayment,
        getTotalDriverPayment,
        totalIncompleteRides,
        getTotalIncompleteRides,
        getTotalAmountToReceive,
        getTotalAmountToPayment,
        bulkUpdate,
        editReservation,
        customersReport,
        driversReport,
        servicesReport,
        getCustomersReport,
        getDriversReport,
        getServicesReport
    } = useReservation();

    const [showFilters, setShowFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(null);
    const [checks, setChecks] = useState([]);
    const [modal, setModal] = useState(false);
    const [report, setReport] = useState('customer')

    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);
    const page = params.get('page') ? parseInt(params.get('page')) : 1;
    const searchText = params.get('search') ? params.get('search') : '';
    const date__gte = params.get('date__gte') ? params.get('date__gte') : '';
    const date__lte = params.get('date__lte') ? params.get('date__lte') : '';
    const time = params.get('time') ? params.get('time') : '';
    const service = params.get('service') ? params.get('service') : '';
    const customer__in = params.get('customer__in') ? params.get('customer__in') : '';
    const driver__in = params.get('driver__in') ? params.get('driver__in') : '';
    const driver_payment = params.get('driver_payment') ? params.get('driver_payment') : '';
    const customer_payment = params.get('customer_payment') ? params.get('customer_payment') : '';

    useEffect(() => {
        if(date__gte !== '' && date__lte !== '') {
            getData()
        }
    }, [page, searchText, date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment])

    useEffect(() => {
        document.body.classList.add('reservations');
        
        if(!(date__gte || date__lte)) {
            navigate(`/reservations?date__gte=${moment().format('YYYY-MM-DD')}&date__lte=${moment().format('YYYY-MM-DD')}`)
        }

        getAllServices();
        getUsers(1, 1, '', true);
        getUsers(2, 1, '', true);

        return () => {
            document.body.classList.remove('reservations');
        };
    }, [])

    const getData = () => {
        setChecks([]);
        if(searchText) {
            getReservations(page, searchText, '', '', '', '', '', '', '', '');   
        } else {
            getReservations(page, '', date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment);
        }
        
        if(profile.groups[0].name === 'Drivers') {
            if(profile.groups[0].name === 'Drivers' && profile.can_view_price) {
                getTotalDriverPayment(searchText, date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment);
            }
            getTotalIncompleteRides(searchText, date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment);
        }

        if(profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers') {
            getTotalAmountToReceive(searchText, date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment);
        }

        if(profile.groups[0].name === 'Administrators') {
            getTotalAmountToPayment(searchText, date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment);
        }
    }

    const handleDelete = (item) => {
        confirmAlert({
            title: <p className='fs-6 fw-semibold mb-0'>Eliminar reserva</p>,
            message: <p>Tem certeza que deseja eliminar <b>{item.id}</b></p>,
            buttons: [
              {
                label: 'Sim',
                onClick: async () => {
                    let res = await deleteReservation(item.id);
                    if(res.status == 204) {
                        getData();
                        toast.success(`${item.id} foi eliminado com sucesso!`);
                    }
                    else {
                        toast.error(`Não foi possível eliminar ${item.id}`);
                    }
                }
              },
              {
                label: 'Não'    
              }
            ]
        });
    }

    const handleFilterRemove = (filterName) => {
        const updatedFilters = activeFilters.filter(filter => filter !== filterName);
        setActiveFilters(updatedFilters);
    
        // Remova o filtro da URL
        const queryParams = queryString.parse(search);
        delete queryParams[filterName];
        const newSearch = queryString.stringify(queryParams);
        navigate(`${pathname}?${newSearch}`);
    };

    const handlePayDrivers = () => {
        confirmAlert({
            title: <p className='fs-6 fw-semibold mb-0'>Pagar motorista</p>,
            message: <p>Tem certeza que deseja pagar os motoristas selecionados?</p>,
            buttons: [
              {
                label: 'Sim',
                onClick: async () => {
                    let res = await bulkUpdate({
                        selected_reservation_ids: checks,
                        fields_to_update: {
                            driver_payment: true
                        }
                    });
                    
                    if(res.status == 200) {
                        getData();
                        toast.success(`O status de pagamento foi atualizado.`);
                    }
                    else {
                        toast.error(`Não foi possível atualizar o status de pagamento`);
                    }
                }
              },
              {
                label: 'Não'    
              }
            ]
        });
    }

    const handlePayCustomer = () => {
        confirmAlert({
            title: <p className='fs-6 fw-semibold mb-0'>Pagamento da empresa</p>,
            message: <p>Tem certeza que deseja marcar como pagamento da empresa efetuado?</p>,
            buttons: [
              {
                label: 'Sim',
                onClick: async () => {
                    let res = await bulkUpdate({
                        selected_reservation_ids: checks,
                        fields_to_update: {
                            customer_payment: true
                        }
                    });
                    
                    if(res.status == 200) {
                        getData();
                        toast.success(`O status de pagamento foi atualizado.`);
                    }
                    else {
                        toast.error(`Não foi possível atualizar o status de pagamento`);
                    }
                }
              },
              {
                label: 'Não'    
              }
            ]
        });
    }

    const getDateStatus = (item, status) => {
        let datetime = item?.status_details?.find((_item) => _item.status === status)?.datetime
        if(datetime) {
            return moment(datetime).format('DD-MM-YYYY HH:mm')
        }
        return null;
    }

    const getReports = () => {
        setModal(true)
        if(profile.groups[0].name === 'Administrators') {
            getCustomersReport(searchText, date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment);
            getDriversReport(searchText, date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment);            
        }
        if(profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers' || profile.groups[0].name === 'Drivers') {
            getServicesReport(searchText, date__gte, date__lte, time, service, customer__in, driver__in, driver_payment, customer_payment);
        }
        if(profile.groups[0].name === 'Customers' || profile.groups[0].name === 'Drivers') {
            setReport('services')
        }
    }

    const mappedServices = allServices.map(item => ({
        value: item.id,
        label: item.name
    }));

    const mappedCustomers = customers.map(item => ({
        value: item.id,
        label: item.first_name +  ' ' + item.last_name 
    })).sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();
        return nameA.localeCompare(nameB);
    });

    const mappedDrivers = drivers.map(item => ({
        value: item.id,
        label: item.first_name +  ' ' + item.last_name
    })).sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();
        return nameA.localeCompare(nameB);
    });


    let filters = [
        {
            type: 'group',
            childrens: [
                {
                    type: 'date',
                    name: 'date__gte',
                    label: 'De'
                },
                {
                    type: 'date',
                    name: 'date__lte',
                    label: 'Até'
                }
            ]
        },
        {
            type: 'time',
            name: 'time',
            label: 'Hora'
        },
        {
            type: 'select',
            name: 'service',
            label: 'Serviço',
            options: mappedServices
        }
    ]

    if(profile.groups[0].name === 'Customers' || profile.groups[0].name === 'Administrators') {
        filters.push({
            type: 'checkbox',
            name: 'driver',
            label: 'Motorista',
            options: mappedDrivers
        })
    }
    if(profile.groups[0].name === 'Administrators') {
        filters.push({
            type: 'checkbox',
            name: 'customer',
            label: 'Empresa',
            options: mappedCustomers
        })
    }
    if(profile.groups[0].name === 'Administrators') {
        filters.push({
            type: 'select',
            name: 'driver_payment',
            label: 'Pagamento do motorista',
            options: [
                {value: false, label: 'Pendente'},
                {value: true, label: 'Pago'}
            ]
        },
        {
            type: 'select',
            name: 'customer_payment',
            label: 'Pagamento da empresa',
            options: [
                {value: false, label: 'Pendente'},
                {value: true, label: 'Pago'}
            ]
        })
    }
    
    let totalCustomersReport = {
        total_paid: 0,
        driver_fee: 0,
        profit: 0
    }
    customersReport?.forEach((item) => {
        totalCustomersReport.total_paid += item.total_paid
        totalCustomersReport.driver_fee += item.driver_fee
        totalCustomersReport.profit += (item.total_paid - item.driver_fee)
    })

    let totalDriversReport = {
        total_earnings: 0,
        total_paid: 0,
        profit: 0
    }
    driversReport?.forEach((item) => {
        totalDriversReport.total_earnings += item.total_earnings
        totalDriversReport.total_paid += item.total_paid
        totalDriversReport.profit += (item.total_earnings - item.total_paid)
    })

    let totalServicesReport = {
        total_reservations: 0,
        total_earnings: 0,
        total_driver_fee: 0,
        profit: 0
    }
    servicesReport?.forEach((item) => {
        totalServicesReport.total_reservations += item.total_reservations
        totalServicesReport.total_earnings += item.total_earnings
        totalServicesReport.total_driver_fee += item.total_driver_fee
        totalServicesReport.profit += (item.total_earnings - item.total_driver_fee)
    })


    return (
        <>
            <h2 className='fs-6 fw-semibold my-4'>Reservas</h2>

            {/* {profile.groups[0].name === 'Administrators' ?
            <Row className='mb-5'>
                <Col>
                    <Card>
                        <CardBody>
                            <div>
                                <span className='fs-1'>$ {totalAmountToReceive?.total_amount_to_receive}</span>
                                {!totalAmountToReceive ? <Spinner /> : null}
                            </div>
                            Total a receber
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <div>
                                <span className='fs-1'>$ {totalAmountToPayment?.total_amount_to_payment}</span>
                                {!totalAmountToPayment ? <Spinner /> : null}
                            </div>
                            Total a pagar
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <div>
                                <span className='fs-1'>$ {totalAmountToReceive?.total_amount_to_receive && totalAmountToPayment?.total_amount_to_payment ? totalAmountToReceive?.total_amount_to_receive - totalAmountToPayment?.total_amount_to_payment : ''}</span>
                                {!totalAmountToPayment ? <Spinner /> : null}
                            </div>
                            Receita
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <div>
                                <span className='fs-1'>{reservations?.length}</span>
                                {loading === 'get_reservations' ? <Spinner /> : null}
                            </div>
                            Total de reservas
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            : null} */}

            {profile.groups[0].name === 'Drivers' ?
            <Row className='mb-5'>
                {profile.groups[0].name === 'Drivers' && profile.can_view_price ?
                <Col xs={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <span className='fs-1'>$ {totalDriverPayment?.total_payment}</span>
                                {!totalDriverPayment ? <Spinner /> : null}
                            </div>
                            Total a receber
                        </CardBody>
                    </Card>
                </Col>
                : null}

                <Col xs={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <span className='fs-1'>{totalIncompleteRides?.total_incomplete_rides}</span>
                                {!totalIncompleteRides ? <Spinner /> : null}
                            </div>
                            Total de serviços a realizar
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            : null}

            {profile.groups[0].name === 'Customers' ?
            <Row className='mb-5'>
                {totalAmountToReceive?.map((item, key) => {
                    return (
                        <Col key={key}>
                            <Card className='h-100'>
                                <CardBody>
                                    <div>
                                        <span className='fs-1'>$ {item?.total_amount_to_receive}</span>
                                        {!item ? <Spinner /> : null}
                                    </div>
                                    Total a pagar <br/>
                                    {totalAmountToReceive.length ? <small>({item?.customer.name})</small> : null}
                                </CardBody>
                            </Card>
                        </Col> 
                    )
                })}
                <Col>
                    <Card className='h-100'>
                        <CardBody>
                            <div>
                                <span className='fs-1'>{reservations?.length}</span>
                                {loading === 'get_reservations' ? <Spinner /> : null}
                            </div>
                            Total de reservas
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            : null}

            <Row className='mb-4'>
                <Col>
                    {permissions.includes('add_reservation') ? <Button color='primary' onClick={() => navigate('/reservations/create')}>Criar reserva</Button> : null}
                    {profile.can_edit_fields.split(',').includes('view_reports') || profile.groups[0].name === 'Customers' || profile.groups[0].name === 'Drivers' ?
                        <>
                            <Button color='primary' outline className='ms-2' onClick={() => getReports()}>Relatório</Button>
                        </>
                    : null}
                    {permissions.includes('change_reservation') && profile.groups[0].name === 'Administrators' && checks.length > 0 ? <Button color='primary' outline className='ms-2' onClick={() => handlePayDrivers()}>Pagar motorista</Button> : null}
                    {permissions.includes('change_reservation') && profile.groups[0].name === 'Administrators' && checks.length > 0 ? <Button color='primary' outline className='ms-2' onClick={() => handlePayCustomer()}>Empresa pagou</Button> : null}
                </Col>
                <Col className='text-end'>
                    <div className='d-inline-block me-2'>
                        <Search />
                    </div>
                    <Button 
                        color='light' 
                        outline 
                        className='d-inline-flex bg-white text-secondary shadow-sm  border-secondary border-opacity-25 px-2 justify-content-center align-items-center' 
                        style={{width: 39, marginTop: -4}}
                        onClick={() => setShowFilters(true)}
                    >
                        <i className="material-icons fs-5">filter_alt</i>
                    </Button>
                </Col>
            </Row>

            {activeFilters.length ?
            <Row>
                <Col className='text-end'>
                    <div>
                        <strong>Filtros Ativos: </strong>
                        {activeFilters.map((filter, index) => (
                        <Badge
                            key={index}
                            color="info"
                            className="ms-1"
                            onClick={() => handleFilterRemove(filter)}
                            style={{ cursor: 'pointer' }}
                        >
                            {filter} &times;
                        </Badge>
                        ))}
                    </div>
                </Col>
            </Row>
            : null}

            {
                loading === 'get_reservations' ? 
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner className='me-1' /> Carregando...
                </div>
                : 
                <Table responsive className='table-clean'>
                        <thead>
                            <tr>
                                {profile.groups[0].name === 'Administrators' ? 
                                    <th>
                                        <Input
                                            type="checkbox"
                                            className="check-all"
                                            checked={checks.length > 0 && checks.length === reservations?.length}
                                            onChange={(e) => {
                                                if(e.target.checked) {
                                                    let ids = [];
                                                    reservations.forEach((item) => {
                                                        ids.push(item.id);
                                                    })
                                                    setChecks(ids);
                                                }
                                                else {
                                                    setChecks([]);
                                                }
                                            }}
                                        />
                                    </th> 
                                : null}
                                <th>ID</th>
                                <th>DATA</th>
                                <th>HORA</th>
                                <th>SERVIÇO</th>
                                {(profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers') && profile.can_view_price ? <th>PREÇO</th> : null}
                                {profile.groups[0].name === 'Administrators' || (profile.groups[0].name === 'Drivers' && profile.can_view_price) ? <th>COMISSÃO</th> : null}
                                {(profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers' || profile.groups[0].name === 'Drivers') ? <th>EMPRESA</th> : null}
                                <th>MOTORISTA</th>
                                <th>CARRO GRANDE</th>
                                {/* {profile.groups[0].name === 'Drivers' || profile.groups[0].name === 'Administrators' ?  <th>{profile.groups[0].name === 'Administrators' ? 'PG. MOTORISTA' : 'PAGAMENTO'}</th> : null} */}
                                {/* {profile.groups[0].name === 'Administrators' ?  <th>PG. EMPRESA</th> : null} */}
                                <th>STATUS</th>
                                <th>OBSERVAÇÕES</th>
                                
                                <th className='text-center'>AÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservations?.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        {profile.groups[0].name === 'Administrators' ? 
                                        <td>
                                            <Input
                                                type="checkbox"
                                                checked={checks.includes(item.id)}
                                                onChange={(e) => {
                                                    if(e.target.checked) setChecks([...checks, item.id])
                                                    else setChecks(checks.filter((check) => check !== item.id))
                                                }}
                                            />
                                        </td> 
                                        : null}
                                        <td><Link to={`/reservations/${item.id}/`}>#{item.id}</Link></td>
                                        <td>{moment(item.datetime).format('DD-MM-YYYY')}</td>
                                        <td>{moment(item.datetime).format('HH:mm')}</td>
                                        <td>{item.service__name}</td>
                                        {(profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers') && profile.can_view_price ? <td>{item.price}</td> : null}
                                        {profile.groups[0].name === 'Administrators' || (profile.groups[0].name === 'Drivers' && profile.can_view_price) ? <td>{item.driver_fee}</td> : null}
                                        {(profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers' || profile.groups[0].name === 'Drivers') ? <td>{item.customer__name}</td> : null}

                                        <td>
                                        {
                                            profile.is_superuser || profile.can_edit_fields.split(',').includes('driver') && !item?.status ? 
                                                <select className='form-select' value={item.driver} onChange={async (e) => {
                                                    const res = await editReservation(item.id, {...item, driver: parseInt(e.target.value)});
                                                    if(res.status == 200) {
                                                        getData();
                                                        toast.success(`Motorista adicionado com sucesso!`);
                                                    }
                                                    else {
                                                        toast.error(`Não foi possível adicionar o motorista`);
                                                    }
                                                }}>
                                                    <option value={null}></option>
                                                    {mappedDrivers?.map((item, key) => {
                                                        return <option value={item.value}>{item.label}</option>
                                                    })}
                                                </select>
                                            : 
                                                item.driver ? item.driver__name : '-'
                                        }
                                        </td>
                                        <td>{item.is_big_car ? 'SIM' : 'NÃO'}</td>

                                        <td>
                                            {item.status === null ?  <Badge color='secondary'>Não iniciado</Badge> : ''}
                                            {item.status === '1' ? <Badge color='info'>A caminho <span className='d-block mt-1'>{getDateStatus(item, '1')}</span></Badge> : ''}
                                            {item.status === '2' ? <Badge color='warning'>Esperando o cliente <span className='d-block mt-1'>{getDateStatus(item, '2')}</span></Badge> : ''}
                                            {item.status === '3' ? <Badge color='primary'>Viagem iniciada <span className='d-block mt-1'>{getDateStatus(item, '3')}</span></Badge> : ''}
                                            {item.status === '4' ? <Badge color='success'>Viagem finalizada <span className='d-block mt-1'>{getDateStatus(item, '4')}</span></Badge> : ''}
                                        </td>

                                        {/* {profile?.groups[0].id === 2 || profile?.groups[0].id === 3 ? 
                                        <td>{item.driver_payment ? <Badge color='success'>PAGO</Badge> : <Badge color='warning'>PENDENTE</Badge>}</td>
                                        : null} */}

                                        {/* {profile.groups[0].name === 'Administrators' ? 
                                            <td>{item.customer_payment ? <Badge color='success'>PAGO</Badge> : <Badge color='warning'>PENDENTE</Badge>}</td> 
                                        : null} */}

                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {item?.comments ? 
                                                <div className='text-truncate d-inline-block' style={{width: 100}}>
                                                    {item?.comments ? <span id={'tooltip_' + item.id.toString()} dangerouslySetInnerHTML={{ __html: addLinkWhatsApp(item.comments) }} /> : null}
                                                    <Tooltip
                                                        isOpen={tooltipOpen === item.id}
                                                        target={'tooltip_' + item.id.toString()}
                                                        placement='bottom'
                                                        toggle={() => setTooltipOpen(!tooltipOpen ? item.id : null)}
                                                    >
                                                        {item.comments?.split('\n').map((paragraph, index) => (<p className='mb-0 text-start' key={index}>{paragraph}</p>))}
                                                    </Tooltip>
                                                </div>
                                                : null}

                                                {/\bkit\b/i.test(item.comments) ? <Badge className='ms-1' color='info'>KIT</Badge> : null}
                                                {/c[âa]mbio/i.test(item.comments) ? <Badge className='ms-1' color='success'>Câmbio</Badge> : null}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                {permissions.includes('change_reservation') && (profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers') ? 
                                                    <Button size='sm' color='secondary' outline className='d-flex align-items-center ps-1 pe-0'  onClick={() => navigate(`/reservations/${item.id}/`)}><i className="material-icons fs-5 me-1">edit</i></Button>
                                                : 
                                                    <Button size='sm' color='secondary' outline className='d-flex align-items-center ps-1 pe-0'  onClick={() => navigate(`/reservations/${item.id}/`)}><i className="material-icons fs-5 me-1">visibility</i></Button>
                                                }

                                                {profile.is_superuser || ((profile.id === item.customer || profile.can_view_customer.includes(item.customer)) && !item.driver) ?
                                                <Button size='sm' color='danger' outline className='d-flex align-items-center ps-1 pe-0 ms-2' onClick={() => handleDelete(item)}><i className="material-icons fs-5 me-1">delete</i></Button> 
                                                : null}
                                            </div>
                                            
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            }

            {
                (searchText.length || activeFilters.length) && reservations?.length === 0 ?
                <Card className='mb-5'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        <p className='m-0'>
                            Nenhum resultado foi encontrado.
                        </p>
                    </CardBody>
                </Card>
                : 
                null
            }

            <DatePagination />
            

            <Filter
                direction='end'
                isOpen={showFilters}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                toggle={() => setShowFilters(false)}
                fields={filters}
            />

            <Modal isOpen={modal} size='lg'>
                <ModalHeader toggle={() => setModal(!modal)}>
                    {/* <span className='d-print-none'>Relatórios&nbsp;</span> */}
                    <span>
                        Relatório de
                        {report === 'customer' ? ' Empresas ' : ''}
                        {report === 'driver' ? ' Motoristas ' : ''}
                        {report === 'services' ? ' Serviços ' : ''}
                    </span> <br/>
                    {date__gte ? <small style={{fontSize: 14}}>{moment(date__gte, 'YYYY-MM-DD').format('DD/MM/YYYY')}</small> : null}
                    {date__gte && date__lte ? ' - ' : ''}
                    {date__lte ? <small style={{fontSize: 14}}>{moment(date__lte, 'YYYY-MM-DD').format('DD/MM/YYYY')}</small> : null}
                </ModalHeader>
                <ModalBody>

                    <div className='text-center mb-4 d-print-none'>
                        <ButtonGroup>
                            {profile.groups[0].name === 'Administrators' ? <Button color={report === 'customer' ? 'primary' : 'secondary'} onClick={() => setReport('customer')}>Empresas</Button> : null}
                            {profile.groups[0].name === 'Administrators' ? <Button color={report === 'driver' ? 'primary' : 'secondary'} onClick={() => setReport('driver')}>Motoristas</Button> : null}    
                            {profile.groups[0].name === 'Administrators' ? <Button color={report === 'services' ? 'primary' : 'secondary'} onClick={() => setReport('services')}>Serviços</Button> : null}
                        </ButtonGroup>
                    </div>

                    {report === 'customer' ? 
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Empresa</th>
                                <th>Total</th>
                                <th>Comissões</th>
                                {profile.is_superuser ? <th>Receita</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {customersReport?.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{item.customer_name}</td>
                                        <td>R$ {item.total_paid}</td>
                                        <td>R$ {item.driver_fee}</td>
                                        {profile.is_superuser ? <td>R$ {item.total_paid - item.driver_fee}</td> : null}
                                    </tr>
                                )
                            })}
                        </tbody>
                        <thead>
                            <tr>
                                <th>Total:</th>
                                <th>R$ {totalCustomersReport.total_paid}</th>
                                <th>R$ {totalCustomersReport.driver_fee}</th>
                                <th>R$ {totalCustomersReport.profit}</th>
                            </tr>
                        </thead>
                    </Table>
                    : null}

                    {report === 'driver' ? 
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Motorista</th>
                                <th>PIX</th>
                                <th>Total</th>
                                <th>Comissões</th>
                                {profile.is_superuser ? <th>Receita</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {driversReport?.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{item.driver_name}</td>
                                        <td>{item.pix}</td>
                                        <td>R$ {item.total_earnings}</td>
                                        <td>R$ {item.total_paid}</td>
                                        {profile.is_superuser ? <td>R$ {item.total_earnings - item.total_paid}</td> : null}
                                    </tr>
                                )
                            })}
                        </tbody>
                        <thead>
                            <tr>
                                <th>Total:</th>
                                <th></th>
                                <th>R$ {totalDriversReport.total_earnings}</th>
                                <th>R$ {totalDriversReport.total_paid}</th>
                                <th>R$ {totalDriversReport.profit}</th>
                            </tr>
                        </thead>
                    </Table>
                    : null}

                    {report === 'services' ? 
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Serviço</th>
                                <th>Quantidade</th>
                                {profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers' ? <th>Total</th> : null}
                                {profile.groups[0].name === 'Administrators' || (profile.groups[0].name === 'Drivers' && profile.can_view_price) ? <th>Comissões</th> : null}
                                {profile.groups[0].name === 'Administrators' && profile.is_superuser ? <th>Receita</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {servicesReport?.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{item.service_name}</td>
                                        <td>{item.total_reservations}</td>
                                        {profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers' ? <td>R$ {item.total_earnings}</td> : null}
                                        {profile.groups[0].name === 'Administrators' || (profile.groups[0].name === 'Drivers' && profile.can_view_price) ? <td>R$ {item.total_driver_fee}</td> : null}
                                        {profile.groups[0].name === 'Administrators' && profile.is_superuser ? <td>R$ {item.total_earnings - item.total_driver_fee}</td> : null}
                                    </tr>
                                )
                            })}
                        </tbody>
                        <thead>
                            <tr>
                                <th>Total:</th>
                                <th>{totalServicesReport.total_reservations}</th>
                                {profile.groups[0].name === 'Administrators' || profile.groups[0].name === 'Customers' ? <th>R$ {totalServicesReport.total_earnings}</th> : null}
                                {profile.groups[0].name === 'Administrators' || (profile.groups[0].name === 'Drivers' && profile.can_view_price) ? <th>R$ {totalServicesReport.total_driver_fee}</th> : null}
                                {profile.groups[0].name === 'Administrators' ? <th>R$ {totalServicesReport.profit}</th> : null}
                            </tr>
                        </thead>
                    </Table>
                    : null}
                </ModalBody>
            </Modal>
        </>
    )
};
  
export default Reservations;