import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, FormFeedback, CardHeader } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import CustomPagination from '../components/CustomPagination';
import Search from '../components/Search';
import avatar from '../assets/images/avatar.png'

const User = () => {
    const [fields, setFields] = useState({
        is_active: true,
        can_view_customer: []
    });
    const [errors, setErrors] = useState({});
    const { profile, permissions } = useAuth();
    const { createUser, editUser, getUser, user, customers, getUsers, userCompanies, getUserCompanies, getCompanyUser } = useUser();
    const { search } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);
    const page = params.get('page') ? parseInt(params.get('page')) : 1;
    const searchText = params.get('search') ? params.get('search') : '';

    const [canEditFields, setCanEditFields] = useState([]);

    const handleChange = (field, value) => {
        setFields({ ...fields, [field]: value });
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
    
        if (!fields['email']) {
          formIsValid = false;
          errors['email'] = 'Campo obrigatório.';
        }
    
        if (typeof fields['email'] !== 'undefined') {
            let lastAtPos = fields['email'].lastIndexOf('@');
            let lastDotPos = fields['email'].lastIndexOf('.');
    
            if (
                !(
                lastAtPos < lastDotPos &&
                lastAtPos > 0 &&
                fields['email'].indexOf('@@') == -1 &&
                lastDotPos > 2 &&
                fields['email'].length - lastDotPos > 2
                )
            ) {
            formIsValid = false;
            errors['email'] = 'O e-mail é inválido.';
          }
        }
    
        if (!id && !fields['password']) {
            formIsValid = false;
            errors['password'] = 'Campo obrigatório.';
        }
    
        setErrors(errors);
        return formIsValid;
    }

    const hasPermission = () => {
        if(
               (id  && !permissions.includes('change_user'))
            || (!id && !permissions.includes('add_user'))
        ) {
            toast.warning('Seu usuário não tem permissões o suficiente para executar essa ação.');
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (handleValidation() && hasPermission()) {
            let res;
            let groups = profile.groups[0].name === 'Administrators' ? 3 : 1;
            let company = profile.groups[0].name === 'Customers' ? profile.id : null;
            let user_companany = (profile.groups[0].name === 'Customers' && profile.is_company && profile.can_add_user)
            
            let is_company = false;
            if (id && profile.groups[0].name === 'Customers' && fields.is_company && fields.can_add_user) {
                is_company = true;
            }

            if(profile.groups[0].name === 'Customers' && fields?.can_view_customer.length === 0) {
                toast.error('Você precisa selecionar uma empresa.')
                return;
            }

            if(id) {
                res = await editUser(id, {...fields, username: fields.email, groups: [], can_edit_fields: canEditFields.join(','), company: company, is_company: is_company}, user_companany);
            } else {
                res = await createUser({...fields, username: fields.email, groups: [groups], can_edit_fields: canEditFields.join(','), company: company, is_company: is_company});
            }
    
            if (res.status === 201) {
                toast.success('Usuário criado com sucesso.');
                navigate(`/users`);
            }
            else if (res.status === 200 && id) {
                toast.success('Usuário alterado com sucesso.');
                navigate(`/users`);
            } 
            else if(res.response.status === 400) {
                if(res.response.data?.detail) toast.error(res.response.data.detail);
                else {
                    let errors = {};
                    Object.keys(res.response.data).forEach((key) => {
                        errors[key] = res.response.data[key][0];
                    })
                    setErrors(errors)
                }
            }
            else {
                toast.error('Ops! Ocorreu um erro inesperado. Tente novamente. Se o erro persistir, entre em contato com o suporte.');
            }
        }
    }

    const getData = async() => {
        if(id && profile.groups[0].name === 'Administrators') {
            const res = await getUser(id);
            setFields(res.data);
        }

        if(id && profile.groups[0].name === 'Customers') {
            const res = await getCompanyUser(id);
            setFields(res.data);
        }

        if(profile.groups[0].name === 'Customers') {
            getUserCompanies()
        }
    }

    const handleCanEditFields = (e, field) => {
        if(e.target.checked) {
            setCanEditFields([...canEditFields, field])
        } else {
            let _fields = [...canEditFields]
            _fields = _fields.filter((item) => item !== field)
            setCanEditFields(_fields)
        }
    }

    useEffect(() => {
        if(user) setCanEditFields(user.can_edit_fields.split(',').filter(item => item !== ''))
    }, [user])

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <h2 className='fs-6 fw-semibold my-4'>{!id ? 'Criar usuário' : `Editar ${user?.email}`}</h2>

            <Card>
                <CardBody>
                    <Form onSubmit={handleSubmit} noValidate>
                        <Row className='mb-4'>
                            <Col md={6}>
                                <FormGroup>
                                    <Label check htmlFor='first_name'>Nome</Label>
                                    <Input
                                    type='text'
                                    id='first_name'
                                    name='first_name'
                                    value={fields?.first_name}
                                    invalid={errors?.first_name}
                                    onChange={(e) => handleChange('first_name', e.target.value)}
                                    />
                                    <FormFeedback>{errors?.first_name}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label check htmlFor='last_name'>Sobrenome</Label>
                                    <Input
                                    type='text'
                                    id='last_name'
                                    name='last_name'
                                    value={fields?.last_name}
                                    invalid={errors?.last_name}
                                    onChange={(e) => handleChange('last_name', e.target.value)}
                                    />
                                    <FormFeedback>{errors?.last_name}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label check htmlFor='email'>E-mail</Label>
                                    <Input
                                    type='email'
                                    id='email'
                                    name='email'
                                    value={fields?.email}
                                    invalid={errors?.email}
                                    onChange={(e) => handleChange('email', e.target.value)}
                                    />
                                    <FormFeedback>{errors?.email}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label check htmlFor='password'>Senha</Label>
                                    <Input
                                    type='password'
                                    id='password'
                                    name='password'
                                    placeholder={!id ? '' : '******'}
                                    value={fields?.password}
                                    invalid={errors?.password}
                                    onChange={(e) => handleChange('password', e.target.value)}
                                    />
                                    <FormFeedback>{errors?.password}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <Label>&nbsp;</Label>
                                <FormGroup check>
                                    <Input
                                        id="is_active"
                                        type="checkbox"
                                        checked={fields.is_active}
                                        onChange={(e) => handleChange('is_active', e.target.checked ? true : false)}
                                    />
                                    {' '}
                                    <Label check htmlFor='is_active'>Ativo</Label>
                                </FormGroup>
                            </Col>


                            {profile.groups[0].name === 'Administrators' ? 
                            <Col md={12} className='mt-4 mb-3'>
                                <Label check htmlFor='password'>Permissões</Label>
                                <Card className='border' style={{boxShadow: 'none'}}>
                                    <CardBody>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__date"
                                                type="checkbox"
                                                checked={canEditFields.includes('date')}
                                                onChange={(e) => handleCanEditFields(e, 'date')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__date'>Pode editar Data</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__time"
                                                type="checkbox"
                                                checked={canEditFields.includes('time')}
                                                onChange={(e) => handleCanEditFields(e, 'time')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__time'>Pode editar Hora</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__customer"
                                                type="checkbox"
                                                checked={canEditFields.includes('customer')}
                                                onChange={(e) => handleCanEditFields(e, 'customer')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__customer'>Pode editar Empresa</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__driver"
                                                type="checkbox"
                                                checked={canEditFields.includes('driver')}
                                                onChange={(e) => handleCanEditFields(e, 'driver')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__driver'>Pode editar Motorista</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__service"
                                                type="checkbox"
                                                checked={canEditFields.includes('service')}
                                                onChange={(e) => handleCanEditFields(e, 'service')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__service'>Pode editar Serviço</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__price"
                                                type="checkbox"
                                                checked={canEditFields.includes('price')}
                                                onChange={(e) => handleCanEditFields(e, 'price')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__price'>Pode editar Preço</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__driver_fee"
                                                type="checkbox"
                                                checked={canEditFields.includes('driver_fee')}
                                                onChange={(e) => handleCanEditFields(e, 'driver_fee')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__driver_fee'>Pode editar Comissão do motorista</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__is_big_car"
                                                type="checkbox"
                                                checked={canEditFields.includes('is_big_car')}
                                                onChange={(e) => handleCanEditFields(e, 'is_big_car')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__is_big_car'>Pode editar Carro grande</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__driver_payment"
                                                type="checkbox"
                                                checked={canEditFields.includes('driver_payment')}
                                                onChange={(e) => handleCanEditFields(e, 'driver_payment')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__driver_payment'>Pode editar Pagamento do motorista</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_edit_fields__customer_payment"
                                                type="checkbox"
                                                checked={canEditFields.includes('customer_payment')}
                                                onChange={(e) => handleCanEditFields(e, 'customer_payment')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_edit_fields__customer_payment'>Pode editar Pagamento da empresa</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="can_view_reports"
                                                type="checkbox"
                                                checked={canEditFields.includes('view_reports')}
                                                onChange={(e) => handleCanEditFields(e, 'view_reports')}
                                            />
                                            {' '}
                                            <Label check htmlFor='can_view_reports'>Pode ver Relatórios</Label>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            : null}

                            {profile.groups[0].name === 'Customers' ?                                 
                            <Col md={12} className='mt-4 mb-3'>
                                <Label check htmlFor='password'>Empresas</Label>
                                <Card className='border' style={{boxShadow: 'none'}}>
                                    <CardBody>
                                        {userCompanies?.map((customer, key) => {
                                            return (
                                                parseInt(id) !== customer.id ?
                                                <FormGroup key={key} check>
                                                    <Input
                                                        id={"can_view_customer_" + customer.id}
                                                        type="checkbox"
                                                        checked={fields?.can_view_customer?.includes(customer.id)}
                                                        onChange={(e) => {
                                                            let can_view_customer = [...fields.can_view_customer]
                                                            if(e.target.checked) {
                                                                can_view_customer.push(customer.id)
                                                            } else {
                                                                can_view_customer = can_view_customer.filter((item) => item !== customer.id)
                                                            }
                                                            setFields({ ...fields, 'can_view_customer': can_view_customer});
                                                        }}
                                                    />
                                                    {' '}
                                                    <Label check htmlFor={"can_view_customer_" + customer.id}>{customer.first_name +' '+ customer.last_name}</Label>
                                                </FormGroup>
                                                : null
                                            )
                                        })}
                                    </CardBody>
                                </Card>
                            </Col>  
                            : null}                          
                        </Row>
                        {id === undefined && permissions.includes('add_user') ? 
                            <Button color='primary'>Adicionar</Button> 
                        : null}

                        {id && permissions.includes('change_user') ? 
                            <Button color='primary'>Guardar</Button> 
                        : null}
                    </Form>
                </CardBody>
            </Card>
        </>
    )
};
  
export default User;