import React, { useState, useEffect } from 'react';
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

const FilterModal = ({ fields, isOpen, toggle, direction, activeFilters, setActiveFilters }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});


  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const convertedFilters = {};
  
    // Itera sobre os parâmetros da URL
    for (const [key, value] of Object.entries(queryParams)) {
      // Verifique se o parâmetro tem o sufixo '__in'
      if (key.endsWith('__in')) {
        // Se sim, divida o valor por vírgula e transforme-o em um array
        // console.log('value.split', value.split(',').map((str) => parseInt(str)))
        convertedFilters[key.replace(/__in$/, '')] = value.split(',').map((str) => parseInt(str));
      } else {
        // Caso contrário, apenas copie o valor
        convertedFilters[key] = value;
      }
    }
  
    setFilters(convertedFilters);
  
    // Marque os checkboxes com base nos valores da URL
    fields.forEach((field) => {
      if (field.type === 'checkbox') {
        field.options.forEach((option) => {
          if (convertedFilters[field.name] && convertedFilters[field.name].includes(option.value)) {
            handleFilterChange(field.name, option.value, true);
          }
        });
      }
    });
  
    const newActiveFilters = Object.keys(queryParams);
    setActiveFilters(newActiveFilters);
  }, [location.search]);  

  const handleFilterChange = (field, value, isCheckbox=false, checked=null) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      
      if (isCheckbox) {
        if(updatedFilters[field] === undefined) {
          updatedFilters[field] = []
        }

        if(updatedFilters[field].includes(value) && checked !== true) {
          updatedFilters[field] = updatedFilters[field].filter((item) => item !== value)
        } else {
          updatedFilters[field].push(value)
        }        
      } else {
        updatedFilters[field] = value
      }
  
      return updatedFilters;
    });
  };
  
  
  const applyFilters = () => {
    const formattedFilters = {};
  
    // Itera sobre os filtros
    for (const [key, value] of Object.entries(filters)) {
      if (Array.isArray(value)) {
        // Se o valor for um array, coloque '__in' no nome do campo e una os valores com vírgula
        formattedFilters[`${key}__in`] = value.join(',');
      } else {
        // Caso contrário, apenas copie o valor
        formattedFilters[key] = value;
      }
    }
  
    const newSearch = queryString.stringify(formattedFilters);
    navigate(`${location.pathname}?${newSearch}`);
    toggle();
  };

  const selectAll = (fieldName) => {
    let field = fields.find((item) => item.name === fieldName)
    field.options.forEach((option) => {
      handleFilterChange(fieldName, option.value, 'checkbox', true)
    })
  }

  const removeAll = (fieldName) => {
    let field = fields.find((item) => item.name === fieldName)
    field.options.forEach((option) => {
      handleFilterChange(fieldName, option.value, 'checkbox', false)

      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };

        updatedFilters[fieldName] = []
        
        // if(updatedFilters[fieldName] === undefined) {
        //   updatedFilters[fieldName] = []
        // }

        // if(updatedFilters[fieldName].includes(option.value) && checked !== true) {
        //   updatedFilters[fieldName] = updatedFilters[fieldName].filter((item) => item !== option.value)
        // } else {
        //   updatedFilters[fieldName].push(option.value)
        // }        
    
        return updatedFilters;
      });      
    })
  }
  
  
  

  const renderField = (field) => {
    switch (field.type) {
      case 'select':
        return (
          <FormGroup key={field.name}>
            {field.label ? <Label for={field.name}>{field.label}</Label> : null}
            <Input type="select" id={field.name} value={filters[field.name] || ''} onChange={(e) => handleFilterChange(field.name, e.target.value)}>
              <option value="">TODOS</option>
              {field.options.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Input>
          </FormGroup>
        );
      case 'checkbox':
        return (
          <>
          {field.label ?
            <Label for={field.name}>
              {field.label} 
              <small className='text-primary ms-2' style={{cursor: 'pointer'}} onClick={() => selectAll(field.name)}>selecionar todos</small>
              <small className='text-primary ms-2' style={{cursor: 'pointer'}} onClick={() => removeAll(field.name)}>desmarcar todos</small>
            </Label>
          : null}
          <div className='form-control mb-3'>
            {field.options.map((option, key) => {
              return (
                <div key={key}>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      id={field.name + option.label + option.value}
                      checked={(filters[field.name] || []).includes(option.value)}
                      onChange={(e) => handleFilterChange(field.name, option.value, 'checkbox', e.target.checked)}
                    />
                    <Label check for={field.name + option.label + option.value}>
                      {option.label}
                    </Label>
                  </FormGroup>
                </div>
              );
            })}
          </div>
          </>
        );
      case 'date':
        return (
          <FormGroup key={field.name}>
            {field.label ? <Label for={field.name}>{field.label}</Label> : null}
            <Input type="date" id={field.name} value={filters[field.name] || ''} onChange={(e) => handleFilterChange(field.name, e.target.value)} />
          </FormGroup>
        );
      case 'time':
        return (
          <FormGroup key={field.name}>
            {field.label ? <Label for={field.name}>{field.label}</Label> : null}
            <Input type="time" id={field.name} value={filters[field.name] || ''} onChange={(e) => handleFilterChange(field.name, e.target.value)} />
          </FormGroup>
        );
      case 'group':
          return (
            <Row>
            {
              field.childrens.map(child => {
                return <Col>{renderField(child)}</Col>
              })
            }
            </Row>
          )
      default:
        return null;
    }
  };

  return (
    <Offcanvas direction={direction} isOpen={isOpen} toggle={toggle}>
      <OffcanvasHeader closeButton>Filtros</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          {fields.map(field => renderField(field))}
        </Form>
      </OffcanvasBody>
      <div className='w-100 bg-white p-3 border-top'>
        <Button color="primary" className='mt-auto w-100' onClick={applyFilters}>Aplicar Filtros</Button>
      </div>
    </Offcanvas>
  );
};

export default FilterModal;
