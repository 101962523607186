import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(null);

  const getNotifications = async () => {
    setLoading('get_notifications');
    try {
      const res = await axios.get(`/api/notifications/`);
      setNotifications(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getNotification = async (id) => {
    setLoading('get_notification');
    try {
      const res = await axios.get(`/api/notifications/${id}/`);
      setNotification(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setNotification(null);
      setLoading(null);
      return error;
    }
  };

  const editNotification = async (id, data) => {
    setLoading('create_notification');
    try {
      const res = await axios.patch(`/api/notifications/${id}/`, data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  const readNotifications = async () => {
    try {
      const res = await axios.get(`/api/notifications/read_all/`);
      return res;
    } catch (error) {
      return error;
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notification,
        loading,
        getNotifications,
        getNotification,
        editNotification,
        readNotifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;