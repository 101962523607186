import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavItem, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotification } from '../context/NotificationContext';
import { toast } from 'react-toastify';
import avatar from '../assets/images/avatar.png';

const Header = () => {
  const { logout, profile } = useAuth();
  const { notifications, getNotifications, readNotifications } = useNotification();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleLogout = async () => {
    logout();
    navigate('/auth/login/')
  }

  const toggleMenu = () => {
    document.body.classList.toggle('sidebar-show')
  }

  const getAllNotifications = async () => {
    let allNotificaion = await getNotifications();
    
    // allNotificaion.data.forEach((item) => {
    //   toast.info(item.description);
    // })
    return allNotificaion
  }

  const openNotifications = async () => {
    await getAllNotifications();
    readNotifications()
    setNotificationOpen(!notificationOpen)
  }

  useEffect(() => {
    getAllNotifications();
  }, [])

  return (
    <Navbar color="white shadow" light expand="md" className='py-3 px-2'>
      <Nav className="mr-auto" navbar>
        <NavItem>
          <Button color="white" className='ps-0 d-flex align-items-center border-0' onClick={() => toggleMenu()}>
            <i className="material-icons me-2">menu</i>
          </Button>
        </NavItem>
      </Nav>
      <Nav className="ms-auto" navbar>
        <NavItem>
          <Dropdown isOpen={notificationOpen} toggle={() => openNotifications()}>
            <DropdownToggle className='bg-white border-0 text-secondary'>
              <span className='position-relative d-block'>
                {notifications.length ? <span className='bg-danger d-block' style={{width: 5, height: 5, borderRadius: 2.5, position: 'absolute', top: 0, right: 0}}></span> : null}
                <i className="material-icons d-block">notifications</i>
              </span>
            </DropdownToggle>
            <DropdownMenu style={{width: 300}} end>
              <DropdownItem className='border-bottom'><b>Notificações</b></DropdownItem>
              {notifications.length ?
              <ul class="list-group" style={{height: 400, overflow: 'auto'}}>
                {notifications.map((notification, key) => {
                  return (
                    <li key={key} class="list-group-item border-0 border-bottom rounded-0">
                      <small>
                        {/* <b>{notification.title}</b> <br/> */}
                        {notification.description}
                      </small>
                    </li>
                  )
                })}
              </ul>
              : 
                <div className='d-flex justify-content-center align-items-center flex-column' style={{height: 200, overflow: 'auto'}}>
                  <small className='m-0'>Você não tem nenhuma notificação</small>
                </div>
              }
            </DropdownMenu>
          </Dropdown>
        </NavItem>
        <NavItem>
          <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <span className='me-2'>{profile?.email}</span>
            <DropdownToggle className='p-0 rounded-circle border-0'>
              <img src={avatar} className="rounded-circle" width={40} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleLogout()}>Sair</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;