import React, { useEffect } from 'react';
import { Table, Spinner, Badge, Button, Row, Col, Card, CardBody } from 'reactstrap';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import CustomPagination from '../components/CustomPagination';
import Search from '../components/Search';
import avatar from '../assets/images/avatar.png'

const Drivers = () => {
    const { profile, permissions } = useAuth();
    const { users, getUsers, deleteUser, archiveUser, loading } = useUser();
    const { search } = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);
    const page = params.get('page') ? parseInt(params.get('page')) : 1;
    const searchText = params.get('search') ? params.get('search') : '';

    useEffect(() => {
        getUsers(2, page, searchText);
    }, [page, searchText])

    const handleArchived = (item) =>{
        confirmAlert({
            title: <p className='fs-6 fw-semibold mb-0'>Arquivar motorista</p>,
            message: <p>Tem certeza que deseja arquivar <b>{item.username}</b></p>,
            buttons: [
              {
                label: 'Sim',
                onClick: async () => {
                    let res = await archiveUser(item.id);
                    getUsers(2, page, searchText);

                    if(res.status == 200) {
                        toast.success(`${item.username} foi arquivado com sucesso!`);
                    }
                    else {
                        toast.error(`Não foi possível arquivar ${item.username}`);
                    }
                }
              },
              {
                label: 'Não'    
              }
            ]
        })
    }

    const handleDelete = (item) => {
        confirmAlert({
            title: <p className='fs-6 fw-semibold mb-0'>Eliminar motorista</p>,
            message: <p>Tem certeza que deseja eliminar <b>{item.username}</b></p>,
            buttons: [
              {
                label: 'Sim',
                onClick: async () => {
                    let res = await deleteUser(item.id);
                    getUsers(2, page, searchText);

                    if(res.status == 204) {
                        toast.success(`${item.username} foi eliminado com sucesso!`);
                    }
                    else {
                        toast.error(`Não foi possível eliminar ${item.username}`);
                    }
                }
              },
              {
                label: 'Não'    
              }
            ]
        });
    }

    return (
        <>
            <h2 className='fs-6 fw-semibold my-4'>Motoristas</h2>

            <Row className='mb-4'>
                <Col>
                    {permissions.includes('add_user') ? <Button color='primary' onClick={() => navigate('/drivers/create')}>Adicionar motorista</Button> : null}
                </Col>
                {permissions.includes('change_user') ? 
                    <Col style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Link to={`/drivers/archived/`}>Ver Arquivados</Link>
                    </Col>
                : null}
                <Col className='text-end'>
                    <div className='d-inline-block'>
                        <Search />
                    </div>
                </Col>
            </Row>

            {
                loading === 'get_users' ? 
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner className='me-1' /> Carregando...
                </div>
                : 
                <Table responsive className='table-clean'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>FOTO</th>
                                <th>NOME</th>
                                <th>SOBRENOME</th>
                                <th>PIX</th>
                                <th>E-MAIL</th>
                                <th className='text-center'>STATUS</th>
                                <th className='text-center'>AÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.results.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td><Link to={`/drivers/${item.id}/`}>{item.id}</Link></td>
                                        <td><img src={avatar} className='rounded-circle shadow-sm' width={40} height={40} /></td>
                                        <td>{item.first_name ? item.first_name : '-'}</td>
                                        <td>{item.last_name ? item.last_name : '-'}</td>
                                        <td>{item.pix}</td>
                                        <td>{item.email}</td>
                                        <td className={'text-center ' + (item.is_active ? 'text-success' : 'text-danger')}>
                                            <Badge color={item.is_active ? 'success' : 'danger'}>{item.is_active ? 'Ativo' : 'Desativado'}</Badge>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                {permissions.includes('change_user') ? 
                                                <Button size='sm' color='secondary' outline className='d-flex align-items-center'  onClick={() => navigate(`/drivers/${item.id}/`)}>
                                                    <i className="material-icons fs-5">edit</i>
                                                    Editar
                                                </Button>
                                                : null}

                                                {permissions.includes('change_user') ? 
                                                <Button size='sm' color='secondary' outline className='d-flex align-items-center ms-2'  onClick={() => handleArchived(item)}>
                                                    <i className="material-icons fs-5">archive</i>
                                                    Arquivar
                                                </Button>
                                                : null}

                                                {permissions.includes('delete_user') ?
                                                <Button size='sm' color='danger' outline className='d-flex align-items-center ms-2' onClick={() => handleDelete(item)}>
                                                    <i className="material-icons fs-5">delete</i>
                                                    Deletar
                                                </Button> 
                                                : null}
                                            </div>
                                            
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            }

            {users && (users.count / 100) > 1 ?
            <div className='d-flex justify-content-center'>
                <CustomPagination currentPage={page} totalPages={users.count / 100} maxPagesToShow={5} />
            </div>
            : null}

            {
                searchText.length && users?.count === 0 ?
                <Card>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        <p className='m-0'>Nenhum resultado foi encontrado para <b>{searchText}</b></p>
                    </CardBody>
                </Card>
                : 
                null
            }
        </>
    )
};
  
export default Drivers;