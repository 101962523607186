export const extractCodenames = (groups) => {
    const codenames = [];
  
    if (groups && Array.isArray(groups)) {
      groups.forEach(group => {
        if (group.permissions && Array.isArray(group.permissions)) {
          group.permissions.forEach(permission => {
            if (permission.codename) {
              codenames.push(permission.codename);
            }
          });
        }
      });
    }
  
    return codenames;
}
  
export const addLinkWhatsApp = (texto) => {
  const regexTelefone = /\+\d+/g;
  const numeros = texto.match(regexTelefone);

  if (numeros) {
    numeros.forEach(numero => {
      const link = `<a href="https://wa.me/${numero}" target="_blank">${numero}</a>`;
      texto = texto.replace(numero, link);
    });
  }

  return texto;
}