import image from '../assets/svg/login-image.svg';

export const Presentation = () => {
    return (
        <div className='p-4 position-relative h-100 d-flex flex-column justify-content-center text-white'>
            <img src={image} width={300} className='mb-5' />
            <h2 className='fs-1 mb-4'>Bem-vindo ao sistema <br/> da LP TRANSFER!</h2>
            <p className='fs-5 opacity-75'>Gerencie suas viagens em um só lugar.</p>
        </div>
    )
}