import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import Auth from './layouts/Auth';
import Default from './layouts/Default';
import Login from './pages/Login';
import RecoverPassword from './pages/RecoverPassword';
import Trips from './pages/Trips';
import Page404 from './pages/Page404'
import Users from './pages/Users';
import UsersArchived from './pages/UsersArchived'
import User from './pages/User';
import Drivers from './pages/Drivers';
import DriversArchived from './pages/DriversArchived';
import Driver from './pages/Driver';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import CustomersArchived from './pages/CustomersArchived';
import Reservations from './pages/Reservations';
import Reservation from './pages/Reservation';
import Services from './pages/Services';
import Service from './pages/Service';
import ServicesArchived from './pages/ServicesArchived';
import { useAuth } from './context/AuthContext';

function App() {
  const { profile, permissions, getProfile } = useAuth();
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  useEffect(() => {
    // Verificar se as permissões estão carregadas
    if (permissions && permissions.length > 0) {
      setPermissionsLoaded(true);
    }
  }, [permissions]);

  useEffect(() => {
    if(localStorage.token !== undefined) {
      getProfile();
    }
  }, [])

  const hasRequiredPermissions = (requiredPermissions) => {
    return requiredPermissions.every(permission => permissions.includes(permission));
  };

  // console.log('permissionsLoaded', permissionsLoaded)

  if (!permissionsLoaded && localStorage.token) {
    return (
      <div className='bg-primary text-white h-100 d-flex justify-content-center align-items-center'>
        <Spinner className='me-2' /> <span className='fs-6'>Carregando...</span>
      </div>
    )
  }

  return (
    <Fragment>
      <Routes>
        {/* Rota para autenticação */}
        <Route path="/auth/*" element={<Auth />}>
          <Route path="login" element={<Login />} />
          <Route path="recover-password" element={<RecoverPassword />} />
        </Route>

        {/* Rota para a área restrita */}
        <Route element={<Default />}>
          <Route
            path="/trips"
            element={
              hasRequiredPermissions(['view_trip']) ? (
                <Trips />
              ) : (
                <Navigate to="/trips" replace />
              )
            }
          />
          
          {profile?.is_superuser || (profile?.groups[0].name === 'Customers' && (profile.is_company && profile.can_add_user)) ? 
          <Route path="/users">
            <Route
              index
              element={
                (hasRequiredPermissions(['view_user']) && profile?.groups[0].name === 'Administrators') || (hasRequiredPermissions(['view_user']) && profile?.groups[0].name === 'Customers' && (profile.is_company && profile.can_add_user)) ? (
                  <Users />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="create"
              element={
                (hasRequiredPermissions(['add_user']) && profile?.groups[0].name === 'Administrators') || (hasRequiredPermissions(['add_user']) && profile?.groups[0].name === 'Customers' && (profile.is_company && profile.can_add_user)) ? (
                  <User />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="archived"
              element={
                hasRequiredPermissions(['view_user']) ? (
                  <UsersArchived/>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path=":id"
              element={
                (hasRequiredPermissions(['view_user']) && profile?.groups[0].name === 'Administrators') || (hasRequiredPermissions(['view_user']) && profile?.groups[0].name === 'Customers' && (profile.is_company && profile.can_add_user)) ? (
                  <User />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Route>
          : null}
          
          <Route path="/drivers">
            <Route
              index
              element={
                hasRequiredPermissions(['view_user']) ? (
                  <Drivers />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="create"
              element={
                hasRequiredPermissions(['add_user']) ? (
                  <Driver />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="archived"
              element={
                hasRequiredPermissions(['view_user']) ? (
                  <DriversArchived />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path=":id"
              element={
                hasRequiredPermissions(['view_user']) ? (
                  <Driver />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Route>
          <Route path="/customers">
            <Route
              index
              element={
                hasRequiredPermissions(['view_user']) ? (
                  <Customers />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="create"
              element={
                hasRequiredPermissions(['add_user']) ? (
                  <Customer />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="archived"
              element={
                hasRequiredPermissions(['view_user']) ? (
                  <CustomersArchived />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path=":id"
              element={
                hasRequiredPermissions(['view_user']) ? (
                  <Customer />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Route>
          <Route path="/services">
            <Route
              index
              element={
                hasRequiredPermissions(['view_service']) ? (
                  <Services />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="create"
              element={
                hasRequiredPermissions(['add_service']) ? (
                  <Service />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="archived"
              element={
                hasRequiredPermissions(['view_service']) ? (
                  <ServicesArchived />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path=":id"
              element={
                hasRequiredPermissions(['view_service']) ? (
                  <Service />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Route>
          <Route path="/reservations">
            <Route
              index
              element={
                hasRequiredPermissions(['view_reservation']) ? (
                  <Reservations />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="create"
              element={
                hasRequiredPermissions(['add_reservation']) ? (
                  <Reservation />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path=":id"
              element={
                hasRequiredPermissions(['view_reservation']) ? (
                  <Reservation />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Route>

          {/* Rota para outras páginas protegidas */}
          {/* ... adicione outras rotas aqui ... */}

          {/* Rota de redirecionamento padrão */}
          <Route path="*" element={<Navigate to="/reservations" replace />} />
        </Route>
      </Routes>
      <ToastContainer />
    </Fragment>
  );
}

export default App;
