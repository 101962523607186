import React, { useEffect, useState } from 'react';
import { Table, Spinner } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const Trips = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get('/api/spreadsheet/user/data/')
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error('Ops! Ocorreu um erro inesperado. Tente novamente. Se o erro persistir, entre em contato com o suporte.');
            });
    }, []);

    // Agrupar os dados por SEMANA e calcular o valor total a receber
    const groupedData = data.reduce((result, item) => {
        const semana = item.SEMANA;
        result[semana] = result[semana] || [];
        result[semana].push(item);
        return result;
    }, {});

    // Função para renderizar os dados agrupados
    const renderGroupedData = () => {
        const sortedWeeks = Object.keys(groupedData).sort((a, b) => b - a); // Ordenar as semanas em ordem decrescente

        return sortedWeeks.map((semana) => {
            const weekData = groupedData[semana];
            const totalAmount = weekData.reduce((total, item) => total + (item.PAGAMENTO === 'PENDENTE' ? item.Valor : 0), 0);

            return (
                <React.Fragment key={semana}>
                    {weekData.map((item, key) => (
                        <tr key={key}>
                            <td className='text-nowrap'>{item.Data}</td>
                            <td className='text-nowrap'>{item.Hora}</td>
                            <td className='text-nowrap'>{item.Serviço}</td>
                            <td className='text-nowrap'>{item.Empresa}</td>
                            <td className='text-nowrap'>R$ {item.Valor.toFixed(2)}</td>
                            <td className='text-nowrap'>{item.Motorista}</td>
                            <td className='text-nowrap'>{item.Obs}</td>
                            <td className='text-nowrap'>{item.Acessorios}</td>
                            <td className='text-nowrap'>{item.NOME}</td>
                            <td className='text-nowrap'>{item.PAGAMENTO}</td>
                        </tr>
                    ))}
                    <tr>
                        <td className='fs-6' colSpan={4} style={{ background: 'none', boxShadow: 'none' }}>
                            <b>TOTAL A RECEBER: </b>
                            <b>R$ {totalAmount.toFixed(2)}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={10} style={{ height: 20, background: 'none', boxShadow: 'none' }}></td>
                    </tr>
                </React.Fragment>
            );
        });
    };

    return (
        <>
            <h2 className='fs-6 fw-semibold my-4'>Viagens</h2>

            {loading ? (
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner className='me-1' /> Carregando...
                </div>
            ) : (
                <Table responsive className='table-clean'>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Hora</th>
                            <th>Serviço</th>
                            <th>Empresa</th>
                            <th width="150">Valor</th>
                            <th>Motorista</th>
                            <th>Obs</th>
                            <th>Acessorios</th>
                            <th>NOME</th>
                            <th>PAGAMENTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderGroupedData()}
                    </tbody>
                </Table>
            )}
        </>
    );
};

export default Trips;
