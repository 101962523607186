import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { useService } from '../context/ServiceContext';
import CustomPagination from '../components/CustomPagination';
import Search from '../components/Search';
import avatar from '../assets/images/avatar.png'

const Driver = () => {
    const [fields, setFields] = useState({
        is_active: true,
        is_h1: false
    });
    const [errors, setErrors] = useState({});
    const { profile, permissions } = useAuth();
    const { createService, editService, getService, service, loading } = useService();
    const { search } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);
    const page = params.get('page') ? parseInt(params.get('page')) : 1;
    const searchText = params.get('search') ? params.get('search') : '';

    const handleChange = (field, value) => {
        setFields({ ...fields, [field]: value });
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
    
        if (!fields['name']) {
          formIsValid = false;
          errors['name'] = 'Campo obrigatório.';
        }

        if (!fields['price']) {
            formIsValid = false;
            errors['price'] = 'Campo obrigatório.';
        }
    
        setErrors(errors);
        return formIsValid;
    }

    const hasPermission = () => {
        if(
               (id  && !permissions.includes('change_service'))
            || (!id && !permissions.includes('add_service'))
        ) {
            toast.warning('Seu usuário não tem permissões o suficiente para executar essa ação.');
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (handleValidation() && hasPermission()) {
            let res;
            if(id) {
                res = await editService(id, {...fields});
            } else {
                res = await createService({...fields});
            }
    
            if (res.status === 201) {
                toast.success('Serviço criado com sucesso.');
                navigate(`/services`);
            }
            else if (res.status === 200 && id) {
                toast.success('Serviço alterado com sucesso.');
                navigate(`/services`);
            } 
            else if(res.response.status === 400) {
                if(res.response.data?.detail) toast.error(res.response.data.detail);
                else {
                    let errors = {};
                    Object.keys(res.response.data).forEach((key) => {
                        errors[key] = res.response.data[key][0];
                    })
                    setErrors(errors)
                }
            }
            else {
                toast.error('Ops! Ocorreu um erro inesperado. Tente novamente. Se o erro persistir, entre em contato com o suporte.');
            }
        }
    }

    const getData = async() => {
        if(id) {
            const res = await getService(id);
            setFields(res.data);
        }
    }

    useEffect(() => {
        // console.log('profile', profile)
    }, ['profile'])

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <h2 className='fs-6 fw-semibold my-4'>{!id ? 'Criar serviço' : `Editar ${service?.name}`}</h2>

            <Card>
                <CardBody>
                    <Form onSubmit={handleSubmit} noValidate>
                        <Row className='mb-4'>
                            <Col md={12}>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label check htmlFor='name'>Nome</Label>
                                            <Input
                                            type='text'
                                            id='name'
                                            name='name'
                                            value={fields?.name}
                                            invalid={errors?.name}
                                            onChange={(e) => handleChange('name', e.target.value)}
                                            />
                                            <FormFeedback>{errors?.name}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label check htmlFor='price'>Preço</Label>
                                            <Input
                                            type='number'
                                            id='price'
                                            name='price'
                                            value={fields?.price}
                                            invalid={errors?.price}
                                            onChange={(e) => handleChange('price', e.target.value)}
                                            />
                                            <FormFeedback>{errors?.price}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label check htmlFor='driver_fee'>Comissão do motorista</Label>
                                            <Input
                                            type='number'
                                            id='driver_fee'
                                            name='driver_fee'
                                            value={fields?.driver_fee}
                                            invalid={errors?.driver_fee}
                                            onChange={(e) => handleChange('driver_fee', e.target.value)}
                                            />
                                            <FormFeedback>{errors?.driver_fee}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label check htmlFor='description'>Descrição</Label>
                                    <Input
                                    type='textarea'
                                    id='description'
                                    name='description'
                                    value={fields?.description}
                                    invalid={errors?.description}
                                    onChange={(e) => handleChange('description', e.target.value)}
                                    />
                                    <FormFeedback>{errors?.description}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup check>
                                    <Input
                                        id="is_h1"
                                        type="checkbox"
                                        checked={fields.is_h1}
                                        onChange={(e) => handleChange('is_h1', e.target.checked ? true : false)}
                                    />
                                    {' '}
                                    <Label check htmlFor='is_h1'>A1</Label>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup check>
                                    <Input
                                        id="is_active"
                                        type="checkbox"
                                        checked={fields.is_active}
                                        onChange={(e) => handleChange('is_active', e.target.checked ? true : false)}
                                    />
                                    {' '}
                                    <Label check htmlFor='is_active'>Ativo</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        {id === undefined && permissions.includes('add_service') ? 
                            <Button color='primary'>Adicionar</Button> 
                        : null}

                        {id && permissions.includes('change_service') ? 
                            <Button color='primary'>Guardar</Button> 
                        : null}
                    </Form>
                </CardBody>
            </Card>
        </>
    )
};
  
export default Driver;