import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, FormFeedback, Alert, Input, Button, Spinner, Card, CardBody } from 'reactstrap';
import { useNavigate, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { Presentation } from '../components/Presentation';

const RecoverPassword = () => {
  const { login, getProfile, loading, resetPassword } = useAuth();
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [sent, setSent] = useState(false);
  const navigate = useNavigate()

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = 'Campo obrigatório.';
    }

    if (typeof fields['email'] !== 'undefined') {
        let lastAtPos = fields['email'].lastIndexOf('@');
        let lastDotPos = fields['email'].lastIndexOf('.');

        if (
            !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields['email'].indexOf('@@') == -1 &&
            lastDotPos > 2 &&
            fields['email'].length - lastDotPos > 2
            )
        ) {
        formIsValid = false;
        errors['email'] = 'O e-mail é inválido.';
      }
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const res = await resetPassword(fields.email, fields.password);

      if (res.status === 200) {
        setSent(true);
      } 
      else if(res.response.status === 401) {
        if(res.response.data?.detail) toast.error(res.response.data.detail);
        else {
            let errors = {};
            Object.keys(res.response.data).forEach((key) => {
                errors[key] = res.response.data[key][0];
            })
            toast.error(errors);
        }
      }
      else {
        toast.error('Ops! Ocorreu um erro inesperado. Tente novamente. Se o erro persistir, entre em contato com o suporte.');
      }
    }
  }

  return (
    <>
    <div className='login-circle-1'></div>
    <div className='login-circle-2'></div>
      <Container className='h-100'>
        <Row className='h-100'>
          <Col className='login-text' md={{size: 12}} xl={{size: 6}}>
            <Presentation />
          </Col>
          <Col md={{size: 12}} xl={{size: 6}} className='d-flex justify-content-center align-items-center'>
            <Card className='border-0' style={{width: 500}}>
              <CardBody>
                <h1 className='fs-3 fw-bold mb-2'>Recuperar a senha</h1>
                {!sent ?
                    <Form onSubmit={handleSubmit}>
                        <p className='mb-4'>Digite seu e-mail para receber um link de redefinição de senha.</p>
                        <FormGroup>
                            <Input
                            type='text'
                            name='email'
                            placeholder='E-mail'
                            style={{height: 45}}
                            value={fields?.email}
                            invalid={errors?.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                            />
                            <FormFeedback>{errors?.email}</FormFeedback>
                        </FormGroup>
                        <Button color='primary' size='sm' type='submit' disabled={loading} style={{width: 150, height: 45}}>
                            {loading ? 'Aguarde' : 'Enviar'}
                            {loading ? <Spinner size='sm' className='ms-1' /> : null}
                        </Button>
                        <div className='text-center mt-4'>
                          <NavLink to="/auth/login">Ir para a página de login</NavLink>
                        </div>
                    </Form>
                : 
                    <Alert color='success'>Um link de redefinição de senha foi enviado para seu e-mail.</Alert>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RecoverPassword;
