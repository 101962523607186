import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

const DatePagination = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(moment());

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dateGte = queryParams.get('date__gte');
    const dateLte = queryParams.get('date__lte');

    if (dateGte && dateLte) {
      const startDate = moment(dateGte);
      const endDate = moment(dateLte);
      const daysDiff = endDate.diff(startDate, 'days');
      // Defina o número total de dias para navegação aqui
      // Para simplificação, definimos como 7 dias
      const totalPages = 7;
      // Atualize o estado com base no número total de páginas
      setCurrentDate(currentDate => {
        // Certifique-se de que a data atual esteja dentro do intervalo válido
        if (currentDate.isBefore(startDate)) {
          return startDate;
        } else if (currentDate.isAfter(endDate)) {
          return endDate;
        }
        return currentDate;
      });
    }
  }, [location.search]);

  const handleDateChange = (newDate) => {
    const dateGte = newDate.format('YYYY-MM-DD');
    const dateLte = newDate.clone().endOf('day').format('YYYY-MM-DD');
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('date__gte', dateGte);
    queryParams.set('date__lte', dateLte);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handlePreviousDate = () => {
    const newDate = currentDate.clone().subtract(1, 'days');
    setCurrentDate(newDate);
    handleDateChange(newDate);
  };

  const handleCurrentDate = () => {
    const newDate = currentDate.clone();
    setCurrentDate(newDate);
    handleDateChange(newDate);
  };

  const handleNextDate = () => {
    const newDate = currentDate.clone().add(1, 'days');
    setCurrentDate(newDate);
    handleDateChange(newDate);
  };

  return (
    <div>
      <div className="text-center mt-3 d-flex justify-content-center">
        <button className="btn border-0 d-flex align-items-center" onClick={handlePreviousDate}><i className="material-icons">chevron_left</i> {currentDate.clone().subtract(1, 'days').format('DD-MM-YYYY')}</button>
        <button className="btn btn-secondary-outline bg-white" onClick={handleCurrentDate}>{currentDate.clone().format('DD-MM-YYYY')}</button>
        <button className="btn border-0 d-flex align-items-center" onClick={handleNextDate}>{currentDate.clone().add(1, 'days').format('DD-MM-YYYY')} <i className="material-icons">chevron_right</i></button>
      </div>
    </div>
  );
};

export default DatePagination;
