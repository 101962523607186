import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const [users, setUsers] = useState(null);
  const [userCompanies, setUserCompanies] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(null);

  const getUsers = async (groups, page=1, search='', no_pagination=false, ids='', is_archived=false) => {
    setLoading('get_users');
    try {
      const res = await axios.get(`/api/users/?groups=${groups}&page=${page}&search=${search}${no_pagination ? '&no_pagination=true' : ''}&id__in=${ids}&is_archived=${is_archived}`);  
      if(no_pagination) {
        if(groups === 1) setCustomers(res.data);
        else if(groups === 2) setDrivers(res.data);
      } else {
        setUsers(res.data);
      }

      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getCompanyUsers = async (search='') => {
    setLoading('get_users');
    try {
      const res = await axios.get(`/api/users/company-users/?search=${search}`);  
      setUsers(res.data);

      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getCompanyUser = async (id) => {
    setLoading('get_user');
    try {
      const res = await axios.get(`/api/users/${id}/company-users/`);  
      setUser(res.data);

      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getUser = async (id) => {
    setLoading('get_user');
    try {
      const res = await axios.get(`/api/users/${id}/`);
      setUser(res.data);
      setLoading(null);
      return res;
    } catch (error) {
      setUser(null);
      setLoading(null);
      return error;
    }
  };

  const deleteUser = async (id, user_companany=false) => {
    setLoading('delete_user');
    try {
      const res = await axios.delete(`/api/users/${id}/${user_companany ? '?user_companany=true' : ''}`);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const createUser = async (data) => {
    setLoading('create_user');
    try {
      const res = await axios.post(`/api/users/`, data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  const editUser = async (id, data, user_companany=false) => {
    setLoading('create_user');
    try {
      const res = await axios.patch(`/api/users/${id}/${user_companany ? '?user_companany=true' : ''}`, data);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  const archiveUser = async (id) => {
    setLoading('archive_user');
    try {
      const res = await axios.get(`/api/users/${id}/archive/`);
      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  };

  const getUserCompanies = async () => {
    setLoading('get_user_companies');
    try {
      const res = await axios.get(`/api/users/user-companies`);  
      setUserCompanies(res.data);

      setLoading(null);
      return res;
    } catch (error) {
      setLoading(null);
      return error;
    }
  }

  return (
    <UserContext.Provider
      value={{
        users,
        customers,
        drivers,
        user,
        loading,
        getUsers,
        getUsers,
        getUser,
        deleteUser,
        createUser,
        editUser,
        archiveUser,
        getCompanyUsers,
        userCompanies,
        getUserCompanies,
        getCompanyUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;