import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomPagination = ({ currentPage, totalPages, onPageChange, maxPagesToShow = 5 }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    const queryParams = queryString.parse(location.search);
    if (queryParams.page) {
      delete queryParams.page; // Remover o parâmetro "page" da URL se ele existir
    }
    const newQueryParams = { ...queryParams, page: page.toString() };
    const newSearch = queryString.stringify(newQueryParams);

    navigate(`${location.pathname}?${newSearch}`);
    if(onPageChange) onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const halfMaxPages = Math.floor(maxPagesToShow / 2);

    if (totalPages <= maxPagesToShow) {
      for (let page = 1; page <= totalPages; page++) {
        pageNumbers.push(page);
      }
    } else if (currentPage <= halfMaxPages) {
      for (let page = 1; page <= maxPagesToShow; page++) {
        pageNumbers.push(page);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    } else if (currentPage >= totalPages - halfMaxPages) {
      pageNumbers.push(1);
      pageNumbers.push('...');
      for (let page = totalPages - maxPagesToShow + 1; page <= totalPages; page++) {
        pageNumbers.push(page);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push('...');
      for (let page = currentPage - halfMaxPages; page <= currentPage + halfMaxPages; page++) {
        pageNumbers.push(page);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((page, index) => (
      <PaginationItem
        key={index}
        active={page === currentPage}
        disabled={page === '...'}
        onClick={() => (typeof page === 'number' ? handlePageChange(page) : null)}
      >
        <PaginationLink>
          {page}
        </PaginationLink>
      </PaginationItem>
    ));
  };

  return (
    <Pagination aria-label="Page navigation example">
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink first onClick={() => handlePageChange(1)} />
      </PaginationItem>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
      </PaginationItem>

      {renderPageNumbers()}

      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
      </PaginationItem>
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink last onClick={() => handlePageChange(totalPages)} />
      </PaginationItem>
    </Pagination>
  );
};

export default CustomPagination;
